import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react'
import Table from '../../../../components/Table'
import Checkbox from '../../../../components/Checkbox'
import { initialConnectionsFilter, useConnectionsStore } from '../../../../stores/connections/useConnectionsStore'
import { useConnectionsHandlers } from '../../../../services/actions/connections/useConnectionsHandlers'
import { IConnection, ITag } from '../../../../interfaces/models/connection'
import ProfilePhoto from '../../../../components/ProfilePhoto'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers'
import { SidebarTypes } from '../../../../interfaces/store/appStore'
import { adjustedColumnsHandler, capitalLetters, formatNumber} from '../../../../utils'
import Button from '../../../../components/Button'
import SelectAllCheckbox from '../../../../components/SelectAllCheckbox'
import Text from '../../../../components/Text'
import Column from '../../../../components/Column'
import ConnectionTags from '../../../../fragments/ConnectionTags'
import moment from 'moment'
import { ICreateNote, useNotesHandlers } from '../../../../services/actions/notes/useNotesHandlers'
import { useAppStore } from '../../../../stores/application/useAppStore'
import ConnectionActionDropdown from '../../../../fragments/dropdowns/ConnectionActionDropdown'
import { useLocation, useNavigate } from 'react-router-dom'
import EmptyPage from '../../../../components/EmptyPage'
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import useMediaQuery from '../../../../components/useMediaQuery'
import styled from 'styled-components'
import { styles } from '../../../../styles/themes/style'
import Row from '../../../../components/Row'
import AppTag from '../../../../fragments/AppTag'
import AppShortTag from '../../../../fragments/AppShortTag'
import { isLNFirst } from './../../../../utils';
import { getID } from "../../../../utils";

interface IConnectionTableProps {
    selectedTags?: ITag[],
    selectedConnections: IConnection[]
    controlBarHeight?: number;
    selectedConnectionsChange: (connections: IConnection[]) => void;
    selectedAllConnectionsChange: (value: boolean) => void;
}

const ConnectionTable: FunctionComponent<IConnectionTableProps> = ({selectedConnections, controlBarHeight, selectedConnectionsChange, selectedAllConnectionsChange}) => {
    const tableRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const location = useLocation()
    const {
        tag,
        note,
        noTags,
        industry,
        selectedUsersMutual,
        isAutotagsFilterOr,
        autotagsFilter,
        locationSearch,
        jobTitleSearch,
        conversationStatus
    } = location.state || {}

    const isMedium = useMediaQuery("(max-width: 1240px)");
    const isSmall = useMediaQuery("(max-width: 768px)");

    const { store: { connections, connectionsFilter, connectionsParameters } } = useConnectionsStore()
    const {  setConnectionsFilterHandler } = useConnectionsHandlers()
    const { createUpdateNoteHandler } = useNotesHandlers()
    const { openSidebar, closeSidebar, openExtension, showInfoToast } = useAppHandlers()
    const { store: { requestLoading, extensionInstalled, extensionVersion }} = useAppStore()
    const { store: { workspace: { showAutoTags }, user, isLoggedToLinkedin, workspace }} = useAuthStore()
    const [isAllSelected, setIsAllSelected] = useState<boolean>(false)
    const [ connectionsLoading, setConnectionsLoading ] = useState<boolean>(true)
    const [lastFetchTimestamp, setLastFetchTimestamp] = useState<number>(0)
    const [hoveredRowId, setHoveredRowId] = useState<string | null>(null);
    const [autotags] = useState<ITag[]>(
        [
            {
              _id: "New Connection",
              title: "New Connection",
              color: "#5964ff",
              isForbidden: true,
            },
            {
              _id: "LinkedIn 1st",
              title: "LinkedIn 1st",
              color: "#5964ff",
              isForbidden: true,
            },
            {
              _id: "Disconnected",
              title: "Disconnected",
              color: "#8C96AD",
              isForbidden: true,
            },
            {
              _id: "LinkedIn",
              title: "LinkedIn",
              color: "#5964ff",
              isForbidden: true,
            },
            {
              _id: "Prospect",
              title: "Prospect",
              color: "#5964ff",
              isForbidden: true,
            },
            {
              _id: "CSV",
              title: "CSV",
              color: "#5964ff",
              isForbidden: true,
            },
            {
              _id: "Imported",
              title: "Imported",
              color: "#5964ff",
              isForbidden: true,
            },
            {
              _id: "Removal Scheduled",
              title: "Removal Scheduled",
              color: "#8c96ad",
              isForbidden: true,
            },
          ]
    )

    const { page, pageSize, total } = connectionsParameters

    const noResultTitle = "No Connections To Show";
    const noResultDescription = "Install Chrome Extension, Sync Connections, or wait for the Sync to complete. You can also import Connections via CSV import.";

    const columnsInitialState = [
        { header: 'MSG', key: ['messageColumn'], width: '34px', show: true, showSmall: true }, 
        { header: 'NOTE', key: ['note'], width: '34px', show: true, showSmall: true  },
        { header: '', key: ['profilePictureMapped'], width: '45px', show: true },
        { header: 'Name', key: ['fullName'], width: '100px', sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true, showSmall: true },
        { header: 'Headline & Job title', key: ['headlineAndJobTitle'], width: '200px', show: true, showSmall: true },
        { header: 'Company', key: ['company'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true  },
        { header: 'Location', key: ['location'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true  },
        { header: 'Date Connected', key: ['dateConnectedColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: false  },
        { header: 'Languages', key: ['languagesColumn'], show: false },
        { header: 'Date of Birth', key: ['dateOfBirth'], show: false },
        { header: 'Followers', key: ['followerCountColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: false },
        { header: 'Contact Info', key: ['emailColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true },
        ...(!workspace?.isStarter ? [{ header: 'Last Messaged', key: ['lastMessagedColumn'], sort: '', sortHandler: (e: any) => sortClickHandler(e), show: true }] : []),
        { header: 'Tags', key: ['tagsColumn'], show: true, showMedium: true },
        ...(!workspace?.isStarter ? [{ header: 'Shared Connections', key: ['mutualConnections'], show: true, showSmall: true }] : []),
        { header: 'Actions', key: ['actions'], show: true, showSmall: true },
    ]

    const connectionsFilterRef = useRef(connectionsFilter);

    useEffect(() => {
        // Filtering passed as location state
        if(note || tag || noTags || industry || locationSearch?.length || jobTitleSearch?.length || selectedUsersMutual || isAutotagsFilterOr || autotagsFilter || conversationStatus ) {
            setConnectionsFilterHandler({
                ...initialConnectionsFilter,
                ...note ? { note: { value: note, label: note } } : {},
                ...tag ? { selectedTags: [tag] } : {},
                ...noTags ? { noTags: noTags } : {},
                ...industry ? { industry: { value: industry, label: industry }} : {},
                ...isAutotagsFilterOr ? { isAutotagsFilterOr } : {},
                ...autotagsFilter ? { autotagsFilter } : {},
                ...selectedUsersMutual ? { selectedUsersMutual } : {},
                ...conversationStatus ? { conversationStatus } : {},
                ...locationSearch?.length || jobTitleSearch?.length ? { 
                    searchValues: [{
                        uid: getID(), // unique identifier
                        searchValue: locationSearch || jobTitleSearch,
                        isValueSearchOr: 'yes',
                        searchByName: 'yes',
                        searchByHeadline: 'yes',
                        searchByJobTitle: 'yes',
                        searchByCompany: 'yes',
                        searchByLocation: 'yes',
                        searchByNote: 'no',
                        isOutterValueSearchOr: 'no',
                    }]
                } : {}
            })
        }
    }, [])

    useEffect(() => {
        connectionsFilterRef.current = connectionsFilter; // updating reference to have fresh object
    }, [connectionsFilter])

    //Clear router location state
    // useEffect(() => {
    //     if (location.state) navigate(location.pathname, { state: null })
    // }, [navigate, location])

    //add another for is fetching depending on the slug

    const getSortParams = (header: string, sort: string): string => {
        switch (header) {
            case 'Name': return sort
            case 'Company': return `company.${sort}`
            case 'Location': return `location.${sort}`
            case 'Date Connected': return sort === 'za' ? 'oldest' : ''
            case 'Followers': return `followerCount.${sort}`
            case 'Contact Info': return `email.${sort}`
            case 'Last Messaged': return `lastMessaged.${sort === 'za' ? 'desc' : 'asc'}`

            default: return ''
        }
    }

    const sortClickHandler = (props: any) => {
        const adjustedColumns = adjustedColumnsHandler(columnsInitialState, columns, tableRef, isSmall, isMedium, sortClickHandler, true) 

        const selectedColumnIndex = adjustedColumns.findIndex((column: any) => column.key === props.key)
        if (selectedColumnIndex !== -1) {

            props.sort = (props.sort === '' || props.sort === 'za') ? 'az' : 'za'
            adjustedColumns[selectedColumnIndex] = props

            setColumns(adjustedColumns)
        
            setConnectionsFilterHandler({...connectionsFilterRef?.current, sort: getSortParams(props.header, props.sort) })
        }
    }

    useEffect(() => {
        if('getConnectionsPaginate' in requestLoading) setConnectionsLoading(requestLoading?.['getConnectionsPaginate'])
    }, [requestLoading]);


    const selectAllCheckboxHandler = () => {
        const selectedIds = !isAllSelected ? [...connections] : []
        setIsAllSelected(!isAllSelected)
        selectedConnectionsChange(selectedIds)
    }

    useEffect(() => {
        selectedAllConnectionsChange(isAllSelected)
    }, [isAllSelected]);

    const selectCheckboxHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation()

        if(isAllSelected) return showInfoToast({ message: 'You can\'t use this action while all connections are selected.'})

        const index = selectedConnections.findIndex(selectedConnection => selectedConnection._id === connection._id)
        let updatedIds = []

        if (index !== -1) {
            updatedIds = selectedConnections.filter((item: any) => String(item._id) !== String(connection._id));
            selectedConnectionsChange(updatedIds);
            setIsAllSelected(false)
        } else {
            updatedIds = [...selectedConnections, connection];
            selectedConnectionsChange(updatedIds);
            setIsAllSelected(false)
        }
    }

    const noteClickHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation();
        openSidebar(SidebarTypes.NOTE_EDIT_CREATE, { connectionId: connection._id, existingNotes: connection.notes,
            onCloseHandler: () => closeSidebar(SidebarTypes.NOTE_EDIT_CREATE), 
            onCreateHandler: onreateOrUpdateNoteHandler
        })
    }

    const onreateOrUpdateNoteHandler = async (
        noteId: string | null,
        note: ICreateNote,
        updateNotes?: (notes: any) => void
    ) => {
        const result = await createUpdateNoteHandler(noteId, note);
        if (result && updateNotes) {
            updateNotes(result.notes);
        }
    };

    const messageClickHandler = (e: React.MouseEvent<HTMLElement>, connection: IConnection) => {
        e.stopPropagation();
        openExtension({ query: `redirectTo=inbox&messagedConnection=${connection._id}` })
    }

    const selectOnThisPageHadler = () => {
        const newConnections = connections.filter((connection: IConnection) => !selectedConnections.some(selectedConnection => connection._id === selectedConnection._id))
        selectedConnectionsChange([...selectedConnections, ...newConnections])
    }

    const clearHandler = () => {
        setIsAllSelected(false)   
        selectedAllConnectionsChange(false)
        selectedConnectionsChange([])
    }

    const [columns, setColumns] = useState(columnsInitialState)

    useEffect(() => {
        const adjustedColumns = adjustedColumnsHandler(columnsInitialState, columns, tableRef, isSmall, isMedium, sortClickHandler, true) 
        
        setColumns(adjustedColumns)
    }, [connectionsParameters, isMedium, isSmall])

    interface UserInfoProps {
        user: any;
        autotag: any;
        lastMessaged: any;
        removedFromLD: any;
    }

    const UserInfo: React.FC<UserInfoProps> = ({ user, autotag, lastMessaged, removedFromLD }) => {
        let tag = autotags.find((tag) => tag.title === autotag?.title);
        if(tag){
            tag.removedFromLD = removedFromLD;
        }
        return (
            <InfoBoxColumn gap='12px'>
                <Row gap='8px'>
                    <ProfilePhotoColumn>
                    <ProfilePhoto 
                    source={user?.profilePicture} 
                    capitalLetters={
                        !user?.profilePicture 
                        ? `${user?.firstName?.charAt(0).toUpperCase()}${user?.lastName?.charAt(0).toUpperCase()}` 
                        : undefined
                    }  />
                    </ProfilePhotoColumn>

                    <Column>
                        <Text>{user?.firstName + ' ' + user?.lastName}</Text>
                        <TextDescription>{`Last msg: ${lastMessaged?.lastMessagedAt ? moment(lastMessaged?.lastMessagedAt).format('MMM D, YYYY [at] HH:mm') : `n/a`}`}</TextDescription>
                        {tag && <AppTag tag={tag}/> }
                    </Column>
                </Row>
            </InfoBoxColumn>
        );
    };

    const handleMouseEnter = (rowId: string) => setHoveredRowId(rowId);
    const handleMouseLeave = () => setHoveredRowId(null);

    //todo: add no data placeholder and preloaders
    return <Table
        ref={tableRef}
        isConnectionTable
        columns={[
            {
                header:
                    <SelectAllCheckbox
                        isCheckboxChecked={selectedConnections.length > 0 || (isAllSelected ?? false)}
                        pageSize={pageSize}
                        total={total}
                        paginated={connections.length}
                        isAllSelected={isAllSelected ?? false}
                        selected={selectedConnections.length}
                        label={'connections'}
                        selectAllHandler={selectAllCheckboxHandler}
                        selectOnThisPageHadler={selectOnThisPageHadler}
                        clearHandler={clearHandler}
                    />,
                    key: ['checkbox'],
                    width: '25px',
                    showSmall: true
                },
                ...columns
        ]}
        pageLoading={!connections?.length && connectionsLoading ? pageSize : undefined}
        noDataPage={<EmptyPage svgType='templateEmpty' title={noResultTitle} label={noResultDescription} />}
        $controlBarHeight={controlBarHeight}
        data={connections.map((connection: IConnection) => {
            const version = connection?.users.filter((e: any) => e?._id === user?._id)
            const isLinkedinFirst = isLNFirst(connection, user?._id)
            const lastMessagedAt = connection?.lastMessagedAtArray
                ?.filter((lastMessagedAt: any) => user && lastMessagedAt._id === user?._id)
                ?.sort((a: any, b: any) => new Date(b.lastMessagedAt).getTime() - new Date(a.lastMessagedAt).getTime())[0];
            const autoTag = connection?.autoTagsArray?.find((tag) => tag?._id === user._id)?.autoTags[0];
            const isDisconnected = autoTag?.title === 'Disconnected';
            return (
                {
                    object: { ...connection, backgroundColor: isDisconnected ? styles.colors.black100 : undefined},
                    onRowClick: (sentConnection: IConnection) => openSidebar(SidebarTypes.CONNECTION_ACTIONS, { connection: sentConnection }),
                    checkbox: (
                        <Checkbox
                            checked={selectedConnections.some((selectedConnection: IConnection) => selectedConnection._id === connection._id || isAllSelected)}
                            checkboxId={connection._id}
                            onClickHandler={(e: React.MouseEvent<HTMLElement>) => selectCheckboxHandler(e, connection)}
                        />
                    ),
                    fullName: (
                        <Column>
                            <Text $ellipsis='0'>{connection.firstName}</Text>
                            <Text $ellipsis='0'>{connection.lastName}</Text>
                        </Column>
                    ),
                    profilePictureMapped: (
                        <ProfilePhoto source={connection.profilePicture} />
                    ),
                    messageColumn: (
                        <Button transparent disabled={workspace?.isStarter || !isLinkedinFirst || !extensionInstalled || !isLoggedToLinkedin || extensionVersion !== process.env.REACT_APP_VERSION} tooltipMessage={workspace?.isStarter ? 'Upgrade to Pro to unlock this feature' : ''} $smallButton onClickHandler={(e: React.MouseEvent<HTMLElement>) => messageClickHandler(e, connection)} $SVGtype={'message'} />
                    ),
                    note: (
                        <Button $smallButton onClickHandler={(e: React.MouseEvent<HTMLElement>) => noteClickHandler(e, connection)} $SVGtype={connection.notes.length !== 0 ? 'noteFull' : 'note'}></Button>
                    ),
                    headlineAndJobTitle: (
                        <Column>
                            <Text $ellipsis='1' showTooltip>{connection.jobTitle}</Text>
                            <Text $ellipsis='2' $faded showTooltip>{connection.headline}</Text>
                        </Column>
                    ),
                    tagsColumn: (
                        <ConnectionTags backgroundColor={isDisconnected ? styles.colors.black100 : undefined} tagColumn={columns.find(column => column.header === "Tags")} 
                            tags={showAutoTags ? connection?.tags : connection?.tags?.filter(tag => !tag.isForbidden)?.sort((a, b) => a?.title?.localeCompare(b?.title))} />
                    ),
                    dateOfBirth: (
                        <Text>{connection?.birthDate ? moment(connection.birthDate.month + " " + connection.birthDate.day, 'MM DD').format('MMM D') : ''}</Text>
                    ),
                    languagesColumn: (
                        <Column>{connection?.languages?.map((language: any) => { return <Text key={language}>{language}</Text> })}</Column>
                    ),
                    emailColumn: (
                        <Column>
                            <Text $ellipsis='0' showTooltip>{connection.email}</Text>
                            <Text $faded>{connection.phoneNumber}</Text>
                        </Column>
                    ),
                    mutualConnections: (
                        !workspace?.isStarter && (
                        <StyledRowShared
                            onMouseEnter={() => handleMouseEnter(connection._id)}
                            onMouseLeave={handleMouseLeave}>
                            {connection?.users?.map((user: any) => { 
                                return  <OverlappingContainer>
                                            <OverlappingPhoto key={user?._id} className={`connection-version-${user?.version}`} 
                                                source={user?.profilePicture} 
                                                allowTooltip={false}
                                                capitalLetters={
                                                    !user?.profilePicture 
                                                    ? capitalLetters(user)
                                                    : undefined
                                                }  />
                                            <>
                                                {connection?.autoTagsArray?.find((tag) => tag?._id === user._id)?.autoTags[0] && (() => {
                                                    const autoTag = connection?.autoTagsArray?.find((tag) => tag?._id === user._id)?.autoTags[0];
                                                    const foundTag = autotags.find(tag => tag.title === autoTag?.title);
                                                    return foundTag ? <AppShortTag tag={foundTag as ITag} /> : null;
                                                })()}
                                            </>
                                    </OverlappingContainer>
                                })
                            }
                            {(hoveredRowId === connection._id &&
                                <InfoBox>
                                    {connection?.users?.map((user: any) => (
                                        <UserInfo key={user._id} user={user} 
                                            autotag={connection?.autoTagsArray?.find((tag) => tag?._id===user._id)?.autoTags[0]}
                                            lastMessaged={connection?.lastMessagedAtArray?.find((msg: any) => msg?._id===user._id)}
                                            removedFromLD={connection?.removedFromLD} />
                                    ))}
                                </InfoBox>
                            )} 
                        </StyledRowShared>
                        )
                    ),
                    lastMessagedColumn: (
                        <StyledMessaged 
                        tooltipMessage={lastMessagedAt ? `${lastMessagedAt?.sent ? 'Sent at ' : 'Received at '} ${moment(lastMessagedAt?.lastMessagedAt).format('MMM D, YYYY [at] HH:mm')}` : ''}
                        $sent={lastMessagedAt?.sent}>
                            {lastMessagedAt ? moment(lastMessagedAt?.lastMessagedAt).fromNow() : ''}
                        </StyledMessaged>
                    ),
                    followerCountColumn:(
                        <Text>{connection?.followerCount ? formatNumber(connection?.followerCount) : ''}</Text>
                    ),
                    dateConnectedColumn:(
                        <Text>{connection?.connectedAt ? moment(connection.connectedAt).format('MMM D, YYYY [at] HH:mm') : ''}</Text>
                    ),
                    actions: 
                        (<div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <ConnectionActionDropdown connection={connection} backgroundColor={isDisconnected ? styles.colors.black100 : undefined}/>
                        </div>),
                    ...connection
                }
            )
        })}
    />
}

const StyledMessaged = styled(Text)<{$sent: boolean}>`
    color: ${({ $sent }) => $sent ? styles.colors.black300 : styles.colors.green600 }
`
const StyledRow = styled(Row)`
    position: relative;
    & > *:not(:first-child) {
        margin-left: -2px;
    }
`

const InfoBox = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    width: 248px;
    background-color: ${styles.colors.white};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 16px 16px 4px 16px;
    z-index: 100000 !important;
`;
const TextDescription = styled(Text)`
    color: ${styles.colors.black300};
    margin-bottom: 4px;
    font-size: 12px;
    line-height: 18px;
`
const InfoBoxColumn = styled(Column)`
    margin-bottom: 12px;
`
const ProfilePhotoColumn = styled(Column)`
    max-height: 32px;
`

const StyledRowShared = styled(StyledRow)`
    & > *:nth-child(1) {
        margin-left: 0 !important;
        z-index: 1;
    }
    
    & > *:nth-child(2) {
        z-index: 2;
    }
    
    & > *:nth-child(3) {
        z-index: 3;
    }

    & > *:nth-child(4) {
        z-index: 4;
    }
`

const OverlappingContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-left: -25px !important;
`;

const OverlappingPhoto = styled(ProfilePhoto)`
  margin-bottom: 4px;
  width: 24px;
  height: 24px;
`;

export default ConnectionTable