import { FunctionComponent } from 'react'
import { useAppStore } from '../../../stores/application/useAppStore'

import CustomModal from '../CustomModal'

const ConfirmationModal: FunctionComponent = () => {
    const { store: { modal } } = useAppStore()

    return (
        <CustomModal
            headingText={modal?.headingText}
            descriptionText={modal?.descriptionText}
            descriptionTextBold={modal?.descriptionTextBold}
            closeButton
            cancelButton={
                modal?.onCancelHandler ?
                    {
                        text: modal?.cancelBtnTitle || 'Cancel',
                        mediumButton: true,
                        onClickHandler: modal?.onCancelHandler
                    } : undefined
            }
            confirmButton={
                modal?.onConfirmHandler ?
                    {
                        text: modal?.confirmBtnTitle || 'Confirm',
                        mediumButton: !modal?.confirmBtnTitle,
                        customWidth: modal?.confirmBtnTitle,
                        onClickHandler: modal?.onConfirmHandler

                    } : undefined
            }
        />
    )
}

export default ConfirmationModal