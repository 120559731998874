import { FunctionComponent, useCallback, useEffect, useRef, useState } from "react";
import Table from "../../../../components/Table";
import { adjustedColumnsHandler } from "../../../../utils";
import moment from "moment";
import { SidebarTypes } from "../../../../interfaces/store/appStore";
import { useAppHandlers } from "../../../../services/actions/app/useAppHandlers";
import EditDeleteActionDropdown from "../../../../fragments/dropdowns/EditDeleteActionDropdown";
import Column from "../../../../components/Column";
import Text from "../../../../components/Text";
import { useTemplateHandlers } from "../../../../services/actions/template/useTemplateHandlers";
import { useTemplateStore } from "../../../../stores/templates/useTemplateStore";
import { useAppStore } from "../../../../stores/application/useAppStore";
import EmptyPage from "../../../../components/EmptyPage";
import useMediaQuery from "../../../../components/useMediaQuery";
import SelectAllCheckbox from "../../../../components/SelectAllCheckbox";
import Checkbox from "../../../../components/Checkbox";
import styled from "styled-components";
import SvgFragment from "../../../../fragments/SvgFragment";
import { useAuthStore } from "../../../../stores/auth/useAuthStore";

interface ITemplatesTableProps {
    templates?: any
    controlBarHeight?: number;
    templatesParameters?: any;
    templatesFilters?: any;
}

const TemplatesTable: FunctionComponent<ITemplatesTableProps> = ({templates, controlBarHeight, templatesParameters, templatesFilters}) => {
    const tableRef = useRef<HTMLDivElement>(null)
    const isMedium = useMediaQuery("(max-width: 1240px)");
    const isSmall = useMediaQuery("(max-width: 768px)");

    const { openSidebar, showInfoToast } = useAppHandlers()
    const { deleteTemplateHandler, setTemplatesFiltersHandler } = useTemplateHandlers()
    const { store: { requestLoading }} = useAppStore()
    const { store: { user }} = useAuthStore()
    const [ templatesLoading, setTemplatesLoading ] = useState<boolean>(true)
    const { pageSize, total } = templatesParameters
    const { selectedTemplatesIds, isAllSelected } = templatesFilters

    const noResultTitle = "No Templates to Show";
    const noResultDescription = "Create, edit, reuse templates.";

    const columnsInitialState = [
        { header: 'Template name', key: ['templateNameColumn'], show: true, width: '450px', showSmall: true },
        { header: 'Type', key: ['type'], show: true, width: '100px' },
        { header: 'Access', key: ['access'], show: true, width: '100px' },
        { header: 'Date created', key: ['dateCreatedColumn'], show: true },
        { header: 'Author', key: ['authorColumn'], show: true },
        { header: 'Actions', key: ['actions'], show: true, showSmall: true }
    ]

    const [columns, setColumns] = useState(columnsInitialState)


    const selectAllCheckboxHandler = () => {
        setTemplatesFiltersHandler({...templatesFilters, isAllSelected: !isAllSelected})
    }

    const selectOnThisPageHadler = () => {
        const templatesIds = templates.filter((template: any) => template?.author.id === user._id).map((template: any) => template._id)
    
        // Filter out IDs that are already selected
        const newIds = templatesIds.filter((id: any) => !selectedTemplatesIds.includes(id))
    
        setTemplatesFiltersHandler({
            ...templatesFilters,
            isAllSelected: false,
            selectedTemplatesIds: [...selectedTemplatesIds, ...newIds]
        })
    }

    const clearHandler = () => {
        setTemplatesFiltersHandler({...templatesFilters, isAllSelected: false, selectedTemplatesIds: []})
    }

    const selectCheckboxHandler = (id: any) => {
        if(isAllSelected) return showInfoToast({ message: 'You can\'t use this action while all connections are selected.'})
        const index = selectedTemplatesIds.indexOf(id)
        if (index !== -1) {
            const updatedIds = selectedTemplatesIds.filter((selectedId: any) => selectedId !== id);
            setTemplatesFiltersHandler({...templatesFilters, selectedTemplatesIds: updatedIds});
        } else {
            const updatedIds = [...selectedTemplatesIds, id];
            setTemplatesFiltersHandler({...templatesFilters, selectedTemplatesIds: updatedIds});
        }
    }

    useEffect(() => {
        if('paginateTemplates' in requestLoading) setTemplatesLoading(requestLoading?.['paginateTemplates'])
    }, [requestLoading]);

    useEffect(() => {
        const adjustedColumns = adjustedColumnsHandler(columnsInitialState, columns, tableRef, isSmall, isMedium)
        setColumns(adjustedColumns)
    }, [isSmall, isMedium])

    return (
        <Table
            ref={tableRef}
            columns={[
                {
                    header:
                    <SelectAllCheckbox
                        isCheckboxChecked={selectedTemplatesIds?.length > 0 || (isAllSelected ?? false)}
                        pageSize={pageSize}
                        total={total}
                        paginated={templates.filter((template: any) => template?.author.id === user._id).length}
                        isAllSelected={isAllSelected ?? false}
                        selected={selectedTemplatesIds?.length}
                        label={'templates'}
                        selectAllHandler={selectAllCheckboxHandler}
                        selectOnThisPageHadler={selectOnThisPageHadler}
                        clearHandler={clearHandler}
                    />,
                    key: ['checkbox'],
                    width: '36px',
                    showSmall: true
                },

                ...columns
            ]}
            noDataPage={<EmptyPage svgType='templateEmpty' title={noResultTitle} label={noResultDescription} />}
            pageLoading={!templates?.length && templatesLoading ? pageSize : undefined}
            $controlBarHeight={controlBarHeight}
            data={templates?.map((template: any) => {
                return ({
                    ...template?.author?.id === user._id && {
                        onRowClick: () =>  openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template', template: template })
                    },
                    checkbox: (
                        <StyledCheckbox
                            disabled={template?.author?.id !== user._id}
                            checked={template?.author?.id === user._id && (selectedTemplatesIds.some((selectedTemplateId: any) => selectedTemplateId === template._id) || isAllSelected)}
                            onClickHandler={(e: any) => {
                                e.stopPropagation();
                                selectCheckboxHandler(template._id)
                            }}
                            checkboxId={template._id}
                        />
                    ),
                    templateNameColumn: (
                        <Column>
                            <Text $label $ellipsis="2">{template.name}</Text>
                            <Text $ellipsis="3">{template.content}</Text>
                        </Column>
                    ),
                    access: template.isPrivate ? 'Private' : 'Public',
                    dateCreatedColumn: moment(template.createdAt).format('MMM D, YYYY [at] HH:mm'),
                    ...template.author !== 'Default' && {
                        authorColumn: <Text tooltipMessage={template?.author?.email} $bold $ellipsis='0'>{template?.author?.firstName + ' ' + template?.author?.lastName}</Text>
                    },
                    actions: (
                        <div style={{display: 'flex', justifyContent: 'flex-end'}} onClick={(e: any) => e.stopPropagation()}>
                            <EditDeleteActionDropdown
                                title="Duplicate Template"
                                object={template}
                                type='template'
                                deleteEnabled={template?.author?.email !== 'Default' && template?.author?.id === user._id}
                                onDeleteHandler={() => deleteTemplateHandler(template._id)}
                                onEditHandler={() => {openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template', template: template, duplicate: true})}}
                            />
                        </div>
                    ),
                    ...template
                })
            })}

        />
    )
}

const StyledCheckbox = styled(Checkbox)`
    height: fit-content;
`

export default TemplatesTable