import { FunctionComponent, ReactNode, useCallback } from "react";
import styled from "styled-components";
import { useBillingStore } from "../../../../stores/billing/useBillingStore";
import Text from "../../../../components/Text";
import Button from "../../../../components/Button";
import Column from "../../../../components/Column";
import Row from "../../../../components/Row";
import { styles } from "../../../../styles/themes/style";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../interfaces/store/appStore";
import { useBillingHandlers } from "../../../../services/actions/billing/useBillingHandlers";
import { IBillingPlanV2, IPrice } from "../../../../interfaces/models/billingPlan";
import { useExternalHandlers } from "../../../../services/actions/navigation/useExternalHandlers";
import SvgFragment from "../../../../fragments/SvgFragment";
import { formatCurrency, oldBusinessPrices } from "../../../../utils";
import { useAuthStore } from "../../../../stores/auth/useAuthStore";
import TooltipWrapper from "../../../../components/TooltipWrapper";

interface IPlansAndFeaturesProps {
    selectedPrices?: any[],
    currency?: string,
    selectedCycle?: string,
    setSelectedPrice?: (price?: IPrice) => void
}

const PlansAndFeatures: FunctionComponent<IPlansAndFeaturesProps> = ({ selectedPrices, currency, selectedCycle, setSelectedPrice }) => {
    const navigate = useNavigate()

    const { setSelectedPlanAndPriceHandler } = useBillingHandlers()
    const { openDemoProduct } = useExternalHandlers()

    const { store: { billingPlans, billingPlansFeatures } } = useBillingStore()
    const { store: { subscription, trial }} = useAuthStore()

    const enterpriseFeatures = [
        {
          "key": "linkedin_connections_sync",
          "value": "Auto and Manual"
        },
        {
          "key": "14_data_points_constant_expansion",
          "value": "true"
        },
        {
          "key": "csv_import",
          "value": "true"
        },
        {
          "key": "data_export",
          "value": "true"
        },
        {
          "key": "custom_fields",
          "value": "true"
        },
        {
          "key": "google_contacts_calendar_gmail",
          "value": "*coming soon"
        },
        {
          "key": "number_of_contacts_total_fair_usage_policy",
          "value": "Unlimited"
        },
        {
          "key": "crm_table_view_sidebar",
          "value": "true"
        },
        {
          "key": "dashboard_overview",
          "value": "true"
        },
        {
          "key": "tags_auto_manual",
          "value": "true"
        },
        {
          "key": "notes",
          "value": "true"
        },
        {
          "key": "lists",
          "value": "true"
        },
        {
          "key": "tasks_reminders",
          "value": "true"
        },
        {
          "key": "filters",
          "value": "true"
        },
        {
          "key": "disconnect_hide_unhide_follow_unfollow",
          "value": "true"
        },
        {
          "key": "data_enrichment_native_networks_best_effort_basis",
          "value": "Unlimited*"
        },
        {
          "key": "data_enrichment_external",
          "value": "*coming soon"
        },
        {
          "key": "last_messaged_connections",
          "value": "true"
        },
        {
          "key": "pin_star_tag_messages",
          "value": "true"
        },
        {
          "key": "inbox_filters",
          "value": "true"
        },
        {
          "key": "inbox_sidebar_with_data",
          "value": "true"
        },
        {
          "key": "send_up_to_25_personalized_messages",
          "value": "true"
        },
        {
          "key": "create_apply_templates",
          "value": "true"
        },
        {
          "key": "leaddelta_ai_sparkle_openai",
          "value": "true"
        },
        {
          "key": "sync_linkedin_messages_to_crm_coming_soon",
          "value": "*coming soon"
        },
        {
          "key": "sidebar_for_linkedin",
          "value": "true"
        },
        {
          "key": "sidebar_for_linkedin_team_edition",
          "value": "true"
        },
        {
          "key": "sidebar_for_web_coming_soon",
          "value": "*coming soon"
        },
        {
          "key": "pool_your_teams_connections",
          "value": "true"
        },
        {
          "key": "team_level_dual_roles",
          "value": "true"
        },
        {
          "key": "dashboards_for_each_member",
          "value": "true"
        },
        {
          "key": "team_level_filters",
          "value": "true"
        },
        {
          "key": "team_level_tasks_reminders",
          "value": "true"
        },
        {
          "key": "team_level_admin_settings",
          "value": "true"
        },
        {
          "key": "team_level_tags_notes",
          "value": "true"
        },
        {
          "key": "mention_a_teammate_in_a_note",
          "value": "true"
        },
        {
          "key": "data_export_admin_controlled",
          "value": "true"
        },
        {
          "key": "mutual_connections_column",
          "value": "true"
        },
        {
          "key": "relationship_strength",
          "value": "*coming soon"
        },
        {
          "key": "best_path",
          "value": "*coming soon"
        },
        {
          "key": "linkedin",
          "value": "true"
        },
        {
          "key": "zapier",
          "value": "true"
        },
        {
          "key": "pabbly",
          "value": "true"
        },
        {
          "key": "openai",
          "value": "true"
        },
        {
          "key": "hubspot",
          "value": "true"
        },
        {
          "key": "onboarding_center",
          "value": "true"
        },
        {
          "key": "help_center",
          "value": "true"
        },
        {
          "key": "roadmap_participation",
          "value": "true"
        },
        {
          "key": "support_email_chat",
          "value": "true"
        },
        {
          "key": "support_live_via_meets",
          "value": "true"
        },
        {
          "key": "custom_integrations",
          "value": "true"
        },
        {
          "key": "dedicated_customer_success_team",
          "value": "true"
        },
        {
          "key": "custom_onboarding_team_training",
          "value": "true"
        },
        {
          "key": "product_advisory_board_seat",
          "value": "true"
        }
    ]

    const cantSelectSame = subscription?.isValid && !trial && !(subscription?.cancelDate && subscription?.isValid)

    let userDefinedPrice: IPrice | undefined
    const selectedUserBillingPlan = billingPlans.find((plan: IBillingPlanV2) => {
        userDefinedPrice = plan.prices.find((price: IPrice) => price.priceId === subscription.priceId && price.productId === subscription.productId)
        if(userDefinedPrice) return true
    })
    //Old prices, feautre compatablility
    const selectedUserOldBillingPlan = oldBusinessPrices().includes(subscription.priceId) 
      ? {name: 'Business'} 
      : undefined;

    const setSelectedPlanAndPrice = useCallback((plan: IBillingPlanV2) => {
      if(plan?.name === 'Enterprise') {
        openDemoProduct();
        return true;
      }
      if(selectedCycle && currency) {
        if(cantSelectSame && selectedUserBillingPlan && userDefinedPrice && userDefinedPrice?.currency === currency && userDefinedPrice?.interval === selectedCycle && selectedUserBillingPlan?._id === plan?._id){
            return true
        }
        const changeSeats = cantSelectSame && selectedUserBillingPlan && userDefinedPrice && userDefinedPrice?.currency === currency && userDefinedPrice?.interval !== selectedCycle && selectedUserBillingPlan?._id === plan?._id

        const selectedPrice = setSelectedPlanAndPriceHandler(plan, selectedCycle, currency)
        navigate(AppRoutes.BILLING_PLAN, { state: { changeSeats, preSelectedBillingPlan: plan, preSelectedPrice: selectedPrice }})
        setSelectedPrice && setSelectedPrice(selectedPrice)
      }
      
  }, [cantSelectSame, selectedCycle, currency, userDefinedPrice, selectedUserBillingPlan])

    return (
        <Container>
            <StyledRow gap="20px">
                {billingPlans.map((billingPlan: any, index: number) => {
                    const selectedPrice = selectedPrices && selectedPrices.find((plan: any) => plan.name === billingPlan.name)
                    const priceToshow = (selectedPrice.prices?.[0]?.price ?? 0) / 100
                    const isCurrentPlan = cantSelectSame && selectedUserBillingPlan && userDefinedPrice && userDefinedPrice?.currency === selectedPrice.prices?.[0]?.currency && userDefinedPrice?.interval === selectedPrice.prices?.[0]?.interval && selectedUserBillingPlan?._id === billingPlan?._id
                    const isDisabledBusinessStarter =  subscription?.status !== 'canceled' && !trial && (selectedUserBillingPlan?.name === 'Business' || selectedUserOldBillingPlan?.name === 'Business') && billingPlan?.name === 'Starter'
                    return (
                        <StyledColumn key={index} alignItems spaceBetween gap="20px">
                            <Text $black $heading4>{billingPlan.name}</Text>
                            <div>
                              <Text $lighter>
                                {billingPlan.name === 'Enterprise'
                                  ? 'Custom pricing'
                                  : `${formatCurrency(selectedPrice.prices?.[0]?.currency ?? 'usd')}${priceToshow}/${priceToshow > 99 ? 'year' : 'month'}/${billingPlan.name === 'Business' ? 'team' : 'user'}`}
                              </Text>    
                            </div>
                            <Button 
                              $type='blue' 
                              $customDisabled={isCurrentPlan || isDisabledBusinessStarter}
                              disabled={isCurrentPlan || isDisabledBusinessStarter} 
                              onClickHandler={() => setSelectedPlanAndPrice(billingPlan)}>{billingPlan.name === 'Enterprise' ? 'Contact sales' : isCurrentPlan ? 'Your plan' : 'Buy Now'}</Button>
                        </StyledColumn>
                    )
                })}
                {/* Hardcoded Enterprise plan */}
                {/* <StyledColumn alignItems spaceBetween gap="20px">
                    <Text $black $heading4>{'Enterprise'}</Text>
                    <Text $heading4>{'Custom pricing'}</Text>
                    <Button $type='blue' onClickHandler={() => openDemoProduct()}>Contact sales</Button>
                </StyledColumn> */}
            </StyledRow>
            <Overflow>
              {billingPlansFeatures?.map((object: any) => {
                return (<>
                  <Column gap='16px'>
                    <Divider />
                    <Text $black $heading5>{object?.category}</Text>
                  </Column>
                  {object?.features?.map((billingPlansFeature: any, featureIndex: number) => {
                    return (
                      <Column gap="16px" key={featureIndex}>
                        <Divider />
                        <Features>
                          <Text $lighter>{billingPlansFeature.name}
                            {billingPlansFeature.tooltip && 
                            <TooltipWrapper tooltipText={billingPlansFeature.tooltip}>
                              <StyledSvg>
                                <SvgFragment type="help"/>
                              </StyledSvg>
                            </TooltipWrapper>}
                          </Text>
                          <StyledRow gap="20px">
                            {billingPlans.map((plan: any, planIndex: number) => {
                              const matchingFeatures = plan.features.filter((feature: any) => billingPlansFeature.key === feature.key);
                              return (
                                <StyledColumn key={planIndex} alignItems>
                                  {matchingFeatures.length > 0 ? (
                                    matchingFeatures.map((feature: any, featureIndex: number) => (
                                      <Text key={featureIndex}>
                                        {feature.value === "true" ? (
                                          <StyledSvg primaryColor><SvgFragment type="checkmark" /></StyledSvg>
                                        ) : (
                                          <Text $lighter>{feature.value}</Text>
                                        )}
                                      </Text>
                                    ))
                                  ) : (
                                    <StyledSvg primaryColor></StyledSvg>
                                  )}
                                </StyledColumn>
                              );
                            })}
                            {/* Hardcoded Enterprise plan */}
                            {/* <StyledColumn key="enterprise" alignItems>
                              {enterpriseFeatures.filter((feature: any) => billingPlansFeature.key === feature.key).map((feature: any, featureIndex: number) => (
                                <Text key={featureIndex}>
                                  {feature.value === "true" ? (
                                    <StyledSvg><SvgFragment type="checkmark" /></StyledSvg>
                                  ) : (
                                    <Text $lighter>{feature.value}</Text>
                                  )}
                                </Text>
                              ))}
                            </StyledColumn> */}
                          </StyledRow>
                        </Features>
                      </Column>
                    );
                  })}
                </>)
              })}
            </Overflow>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: calc(100% - 40px);
`
const Overflow = styled(Column)`
    overflow: auto;
`
const StyledSvg = styled.div<{primaryColor?: boolean}>`
  &:hover {
    cursor: pointer;
  }
  .svg{
    width: 20px;
    height: 20px;

    ${({primaryColor}) => primaryColor && `
      & path { fill: ${styles.colors.primary600} }
    `}
     
  }
`
const StyledColumn = styled(Column)`
    width: 220px;

    @media (max-width: 768px) {
        width: 100%;
    }
`
const StyledRow = styled(Row)`
    justify-content: end;

    @media (max-width: 768px) {
        width: 100%;
        justify-content: space-around;
    }
`
const Features = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
    }
`
const Divider = styled.div`
    height: 1px;
    margin: 16px 0 0 0;
    background: ${styles.colors.black200};
`

export default PlansAndFeatures