import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useTemplateActions } from "../../../stores/templates/useTemplateActions";
import { useTemplateStore } from "../../../stores/templates/useTemplateStore";
import { useExtensionRequest } from "../../apis/extension/useExtensionRequest";
import { useTemplateRequest } from "../../apis/templates/useTemplateRequest";
import { useAppHandlers } from "../app/useAppHandlers";


export const useTemplateHandlers = () => {

    const { paginateTemplates,  createTemplate, updateTemplate, deleteTemplate, bulkUpdateTemplates, bulkDeleteTemplates } = useTemplateRequest()
    const { setTemplates, setTemplatesParameters, setTemplatesFilters, resetTemplatesStore } = useTemplateActions()
    const { setupTemplatesContextMenu } = useExtensionRequest()
    const { setDestructiveModal, hideModal, showInfoToast, showErrorToast } = useAppHandlers()

    const { store: { templates, templatesParameters, templatesFilters } } = useTemplateStore()
    const { store: { authData }} = useAuthStore()

    const { page, pageSize, totalOwnerCount } = templatesParameters
    const { searchByName, selectedTemplatesIds, isAllSelected, createdAtTo, createdAtFrom, selectedUsersMutual, usersMutualCondition, templateAccessType } = templatesFilters

    const hasFilters = searchByName !== '' || createdAtTo !== '' || createdAtFrom !== '' || selectedUsersMutual?.length || templateAccessType?.value !== null
    const searchParams = {
        page: page, 
        pageSize: pageSize, 
        // sort: sort,
        searchByName: searchByName,
        createdAtTo: new Date(createdAtTo).getTime(),
        createdAtFrom: new Date(createdAtFrom).getTime(),
        selectedUsersMutual: selectedUsersMutual,
        usersMutualCondition: selectedUsersMutual.length ? usersMutualCondition : null,
        templateAccessType: JSON.stringify(templateAccessType.value),
        getAll: 'yes'
    }

    const getTemplatesHandler = async () => {
        const result = await paginateTemplates(searchParams)
        if(result?.templates) {
            setTemplates(result?.templates)//todo: check this
            setTemplatesParameters({
                ...templatesParameters,
                total: result.metadata.totalPaginated,
                totalOwnerCount: result.metadata.totalOwnerCount
            })
        }

        setupTemplatesContextMenu(authData)
    }

    const getTemplatesSelectHandler = async() => {
        const result = await paginateTemplates({
            page: 1,
            pageSize: 100,
            getAll: 'yes'
        })
        return result ?? []
    }

    const createTemplateHandler = async (createOrDuplicate: any, payload: any) => {
        const result = await createTemplate(payload)
        if(result?.success) {
            showInfoToast({message: `Template successfully ${createOrDuplicate}`})
            await getTemplatesHandler()
        } else {
            showErrorToast({message: result?.message})
        }

    }

    const updateTemplateHandler = async (payload: any) => {

        const templateId = payload._id

        await updateTemplate(templateId, payload)
        showInfoToast({message: `Template successfully edited`})
        await getTemplatesHandler()
    }

    const deleteTemplateHandler = async (templateId: string) => {
        setDestructiveModal({
            headingText: "Delete template",
            descriptionText: `Are you sure you want to delete template? This action cannot be undone.`,
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                const result = await deleteTemplate(templateId)
                if(result?.success) {
                    showInfoToast({message: result?.message})
                    setTemplatesFiltersHandler({...templatesFilters, isAllSelected: false, selectedTemplatesIds: []})
                    await getTemplatesHandler()
                }
            }
        })

    }

    const bulkDeleteTemplatesHandler = async() => {
        setDestructiveModal({
            headingText: "Delete templates",
            descriptionText: `Are you sure you want to delete ${isAllSelected ? totalOwnerCount : selectedTemplatesIds.length} ${selectedTemplatesIds.length > 1 || isAllSelected ? 'templates' : 'template'}? This action cannot be undone.`,
            onCancelHandler: () => hideModal(),
            onConfirmHandler: async () => {
                hideModal()
                const result = await bulkDeleteTemplates({...searchParams, isAllSelected, selectedTemplatesIds, hasFilters})
                if(result?.success) {
                    showInfoToast({message: `${isAllSelected ? totalOwnerCount : selectedTemplatesIds.length} template${(isAllSelected ? totalOwnerCount : selectedTemplatesIds.length) > 1 ? 's' : ''} successfully deleted`})
                    setTemplatesFiltersHandler({...templatesFilters, isAllSelected: false, selectedTemplatesIds: []})
                    await getTemplatesHandler()
                }

            }
        })
    }

    const bulkUpdateAccessTemplatesHandler = async(isPrivate: boolean) => {
        const payload = {
            ...searchParams,
            updateAccess: true,
            isAllSelected,
            selectedTemplatesIds,
            isPrivate,
            hasFilters
        }

        const response = await bulkUpdateTemplates(payload)
        if(response) {
            showInfoToast({message: `${isAllSelected ? totalOwnerCount : selectedTemplatesIds.length} template${(isAllSelected ? totalOwnerCount : selectedTemplatesIds.length) > 1 ? 's' : ''} changed to ${isPrivate ? 'private' : 'public'}`})
            const updatedTemplates = templates.map((template: any) => {
                const updatedTemplate = response.find((t: any) => t._id === template._id)

                if (updatedTemplate) return { ...template, isPrivate: updatedTemplate.isPrivate }

                return template;
            })
            setTemplates(updatedTemplates)
        }
    }

    const setTemplatesHandler = (payload: any) => {
        setTemplates(payload)
    }

    const setTemplatesParametersHandler = (payload: any) => {
        setTemplatesParameters(payload)
    }

    const setTemplatesFiltersHandler = (payload: any) => {
        setTemplatesFilters(payload)
    }

    const resetTemplatesStoreHandler = () => {
        resetTemplatesStore();
    }

    const setClearTemplatesFilterHandler = () => {
        resetTemplatesStore()
        getTemplatesHandler()
    }

    const numberOfTemplatesFiltersHandler = () => {
        let numberOfFilters = 0

        if(createdAtTo !== '' || createdAtFrom !== '') numberOfFilters += 1
        if(selectedUsersMutual?.length) numberOfFilters += 1
        if(templateAccessType?.value !== null) numberOfFilters += 1

        return numberOfFilters
    }

    return {
        getTemplatesHandler,
        createTemplateHandler,
        updateTemplateHandler,
        deleteTemplateHandler,
        bulkDeleteTemplatesHandler,
        bulkUpdateAccessTemplatesHandler,
        setTemplatesHandler,
        setTemplatesParametersHandler,
        setTemplatesFiltersHandler,
        getTemplatesSelectHandler,
        resetTemplatesStoreHandler,
        setClearTemplatesFilterHandler,
        numberOfTemplatesFiltersHandler
    };
}
