import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { IEditableTag } from '../ApplyRemoveTagsSelect'
import { IConnection } from '../../interfaces/models/connection'
import Button from '../../components/Button'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import { useAppStore } from '../../stores/application/useAppStore'
import { useConnectionsStore } from '../../stores/connections/useConnectionsStore'
import BulkRemoveTagsSelect from '../BulkRemoveTagsSelect'
import Row from '../../components/Row'

interface IBulkRemoveTagFormProps {
    submitClicked?: boolean,
    connection?: IConnection,
    selectedConnections?: IConnection[],
    selectedAllConnections?: boolean,
    inline?: boolean
    onSaveHandler?: (e: any) => void
 }

const BulkRemoveTagForm: FunctionComponent<IBulkRemoveTagFormProps> = ({connection, selectedConnections, selectedAllConnections, submitClicked, inline, onSaveHandler}) => {
    const [value, setValue] = useState<IEditableTag[]>([])
    const [submitLoading, setSubmitLoading] = useState<boolean>(false)
    const { applyRemoveTagsToConnection, bulkRemoveTags } = useConnectionsHandlers()
    const { store: { requestLoading } } = useAppStore()
    const { store: {connectionFilterOptions: {tagFilterOptions}} } = useConnectionsStore();
    useEffect(()=>{
        if(!!submitClicked) onSubmitHandler()
    }, [submitClicked])

    useEffect(() => {
        if('bulkRemoveTag' in requestLoading) setSubmitLoading(requestLoading?.['bulkRemoveTag'])
    }, [requestLoading]);

    const onSubmitHandler = useCallback(async (event?: FormEvent) => {
        if(event) event.preventDefault()
        setSubmitLoading(true)
        let applyTags: any[] = [] 
        let removeTags: any[] = []
        removeTags = tagFilterOptions.filter((item: { title: string | undefined }) => value.some((tag => tag.title === item.title))).map((tag: { _id: any }) => tag._id);

        if(selectedAllConnections){
            const result = await bulkRemoveTags(removeTags, [])
            onSaveHandler && onSaveHandler(result)
        }
        else if(connection){
            const newConnection = await applyRemoveTagsToConnection(connection._id, applyTags, removeTags)
            onSaveHandler && onSaveHandler(newConnection)
        }
        else {
            const selectedConnectionsIds = selectedConnections?.map(connection => connection._id)
            if(selectedConnectionsIds?.length === 1){
                const newConnection = await applyRemoveTagsToConnection(selectedConnectionsIds[0], applyTags, removeTags)
                onSaveHandler && onSaveHandler(newConnection)
            } else {
                const result = await bulkRemoveTags(removeTags, selectedConnectionsIds ?? [])
                onSaveHandler && onSaveHandler(result)
            }
        }
        setSubmitLoading(false)
    }, [value])
    
    return <StyledForm onSubmit={onSubmitHandler}>
        <BulkRemoveTagsSelect 
            onChangeHandler={(value: IEditableTag[]) => setValue([...value])}
            applicableConnections={selectedAllConnections ? undefined : selectedConnections}
        />
        {!inline && 
        <Row gap="10px">
            <Button $type='blue' type="submit" isLoading={submitLoading}>{'Continue'}</Button>
        </Row>
        }
    </StyledForm>
}

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 10px;
`

export default BulkRemoveTagForm
