import { FunctionComponent } from "react";
import styled from "styled-components";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import SvgFragment from "../SvgFragment";
import DropdownItem from "../../components/dropdown/DropdownItem";
import Row from "../../components/Row";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { useAuthHandlers } from "../../services/actions/auth/useAuthHandlers";
import { styles } from "../../styles/themes/style";
import useMediaQuery from "../../components/useMediaQuery";
import { useTemplateHandlers } from "../../services/actions/template/useTemplateHandlers";
import { useTemplateStore } from "../../stores/templates/useTemplateStore";
import { SidebarTypes } from "../../interfaces/store/appStore";
import { useAppHandlers } from "../../services/actions/app/useAppHandlers";

interface IBulkTemplateActionsDropdownProps {
  updateAccessHandler?: (isPrivate: boolean) => void;
}

const TemplatesBulkActionsDropdown: FunctionComponent<
  IBulkTemplateActionsDropdownProps
> = ({ updateAccessHandler }) => {
  const { getTemplatesHandler, bulkDeleteTemplatesHandler } = useTemplateHandlers();
  const { store: { templatesFilters: { selectedTemplatesIds, isAllSelected } } } = useTemplateStore();
  const { store: { user, workspace } } = useAuthStore();
  const { saveUserPreferencesHandler } = useAuthHandlers();
  const { openSidebar } = useAppHandlers()
  const isDefaultTemplates = user?.preferences?.hideDefaultTemplates;

  const isResponsiveContainerSmall = useMediaQuery("(max-width: 1260px)");
  const isScreenMobile = useMediaQuery("(max-width: 575px)");

  const bulkConnectionActions = [
    {
      icon: "pen",
      title: `Add New Template`,
      slug: "addTemplate",
      enabled: true,
      visible: isScreenMobile,
      destructive: false,
      onClickHandler: () => openSidebar(SidebarTypes.TEMPLATES_EDIT_CREATE, { formType: 'template' }),
    },
    {
      title: `Update Access`,
      slug: "updateAccess",
      enabled: true,
      visible: isScreenMobile,
      destructive: false,
      divider: true,
      onClickHandler: (isPrivate?: boolean) =>
        updateAccessHandler &&
        updateAccessHandler(isPrivate !== undefined ? isPrivate : true),
    },
    {
      icon: "trashCan",
      title: `Delete Templates`,
      slug: "deleteTemplates",
      enabled: selectedTemplatesIds.length > 0 || isAllSelected,
      visible: true,
      destructive: true,
      onClickHandler: () => bulkDeleteTemplatesHandler(),
    },
    {
      icon: isDefaultTemplates ? "show" : "hide",
      title: `${isDefaultTemplates ? "Show" : "Hide"} default templates`,
      slug: "hide",
      enabled: true,
      visible: isResponsiveContainerSmall,
      destructive: false,
      onClickHandler: () => onHideDefaultTemplatesHandler(),
    },
  ];

  const onHideDefaultTemplatesHandler = async () => {
    await saveUserPreferencesHandler({
      hideDefaultTemplates: !isDefaultTemplates,
    });
    getTemplatesHandler();
  };

  return (
    <DropdownMenu
      tooltipMessage={"More actions"}
      title={
        <SvgFragmentWrapper disabled={false}>
          <span className="more-text">More</span>
          <SvgFragment type="arrowDown" />
        </SvgFragmentWrapper>
      }
      $hideToggle
    >
      {bulkConnectionActions.map(
        (connectionAction, index) =>
          connectionAction.visible && (
            connectionAction?.slug === 'updateAccess' ? (
              <>
                <StyledDropdownMenu 
                  className='dropdownWrapperReference' 
                  disabled={(selectedTemplatesIds.length === 0 && !isAllSelected) || !workspace.isBusiness || !connectionAction.enabled} 
                  SVGtype={!workspace.isBusiness ? 'lock2' : ''} 
                  tooltipMessage={!workspace.isBusiness ? 'Upgrade to Business to unlock this feature' : ''} 
                  title={'Update Access'}> 
                        <DropdownItem onClickHandler={() => connectionAction.onClickHandler(true)}>Private</DropdownItem>
                        <DropdownItem onClickHandler={() => connectionAction.onClickHandler(false)}>Public</DropdownItem>
                    </StyledDropdownMenu>
              </>
            ) : (
              <DropdownItem
                $disabled={!connectionAction.enabled}
                key={index}
                $danger={connectionAction.destructive}
                onClickHandler={(e: any) =>
                  connectionAction.onClickHandler &&
                  connectionAction.onClickHandler()
                }
              >
                <Row alignItems gap="5px">
                  <SvgFragment type={connectionAction.icon} />
                  {connectionAction.title}
                </Row>
              </DropdownItem>
            )
          )
      )}
    </DropdownMenu>
  );
};

const SvgFragmentWrapper = styled.div<{ disabled: boolean }>`
  width: 75px;
  @media (max-width: 386px) {
      width: 64px;
  }
  @media (max-width: 376px) {
      width: 60px;
  }
  @media (max-width: 372px) {
      width: 30px;
  }
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ disabled, theme: { quinaryColor } }) =>
    disabled ? quinaryColor : styles.colors.primary600};
  .more-text {
        display: inline;
        @media (max-width: 372px) {
           display: none;
        }
  }
`
const StyledDropdownMenu = styled(DropdownMenu)`
  padding: 6px 20px;
   ${({disabled}) => disabled && `
            & .dropdownWrapperReference {
                color: ${styles.colors.disabledState};
                svg { cursor: default; }
                &:hover { color: ${styles.colors.disabledState}; }
            }
     `
   }

  .actions-dropdown + div{
    min-width: 128px;
  }
`;

export default TemplatesBulkActionsDropdown;
