import { FunctionComponent } from 'react'
import styled from 'styled-components'
import { formatNumber } from '../utils';
import Column from '../components/Column';
import Text from '../components/Text';
import { styles } from '../styles/themes/style';
import LoaderCell from '../components/LoaderCell';
import SvgFragment from './SvgFragment';
import TooltipWrapper from '../components/TooltipWrapper';

interface IStatBoxProps {
    emptyBox?: boolean
    svgType?: string,
    statistic?: number,
    title?: string,
    onClickHandler?: (e: any) => void,
    loading?: boolean,
    tooltipMessage?: string
}

const StatBox: FunctionComponent<IStatBoxProps> = ({ emptyBox = false, svgType, statistic = 0, title, onClickHandler, loading, tooltipMessage }) => {
    
    return (
        <TooltipWrapper placement='top' tooltipText={tooltipMessage?.length ? tooltipMessage : ''}>
            <Container emptyBox={emptyBox} hover={typeof onClickHandler === 'function'} onClick={onClickHandler}>
                { emptyBox ? 
                    <Column>
                        <Text $white $heading3 $bold>Your LeadDelta Dashboard</Text>
                        <Text $white>Stay ahead with your network insights.</Text>
                    </Column> 
                : loading ? 
                    <>
                        <LoaderCell $width='66px' $height='66px' $circle={true}/>
                        <Column gap='20px'>
                            <LoaderCell $width='120px' $height='120px'/>
                            <LoaderCell $width='75px' $height='12px'/>
                        </Column>
                    </> : 
                <>
                    <StyledSvgFragment>
                        <SvgFragment type={svgType}/>
                    </StyledSvgFragment>
                    <Column>
                        <Number className="hoverableText"> {formatNumber(statistic) || 0} </Number>
                        <Text $faded $label> {title} </Text>
                    </Column>
                </>}
            </Container>
        </TooltipWrapper>
    )
}
const Container = styled.div<{hover?: boolean, emptyBox?: boolean}>`
    flex: 1 1 auto; 
    min-height: 90px;
    min-width: 190px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    gap: 12px;
    background: ${({ emptyBox, theme: { secondaryColor }}) => emptyBox ? styles.colors.primary600 : secondaryColor};
    border-radius: 16px;
    border: 2px solid ${({ theme: { secondaryColor }}) => secondaryColor};
    transition: all .2s ease;

    ${({hover}) => hover && `
        &:hover {
            border: 2px solid ${styles.colors.primary600};
            cursor: pointer;
            .hoverableText {
                color: ${styles.colors.black600};
            }
        }
    `}

    & .svg .path{
        fill: ${({ theme: { primary }}) => primary ? styles.colors.primary100 : styles.colors.darkblack400 };
    } 
`
const Number = styled.span`
    font-family: NunitoSans;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    color: ${styles.colors.primary600};
    transition: all .2s ease;
`
const StyledSvgFragment = styled.div`
    & svg {
        width: 60px;
    }
`
export default StatBox