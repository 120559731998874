import { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import Text from '../../../Text'
import CustomModal from '../../CustomModal'
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../../../../stores/auth/useAuthStore'
import { useAppHandlers } from '../../../../services/actions/app/useAppHandlers';
import { AppRoutes } from '../../../../interfaces/store/appStore';
import { formatCurrency, formatPrice } from '../../../../utils';
import Column from '../../../Column';

interface IPriceModel {
    latest_invoice_total?: number
    currency?: string,
    customerEmail?: string,
    interval?: string
}

const PaymentConfirmedModal: FunctionComponent = () => {
    const navigate = useNavigate()
    const { hideModal } = useAppHandlers()
    const { store: { user, subscription, } } = useAuthStore()
    const [priceModel, setPriceModel] = useState<IPriceModel | undefined>()

    useEffect(() => { setPriceModel({latest_invoice_total: subscription.latest_invoice_total, currency: subscription.currency, interval: subscription.interval, customerEmail: user.email}) }, [])
    const price = priceModel?.latest_invoice_total || 0
    return (
        <CustomModal
            checkmark
            $center
            confirmButton={{
                text: 'Ok',
                mediumButton: true,
                onClickHandler: () => { navigate(AppRoutes.SETTINGS_BILLING); hideModal() }
            }}
        >
            <TextWrapper>
                <Column alignItems gap="10px">
                    <Text $heading3 $black>{'Payment confirmed'}</Text>
                    <StyledText $lighter><span>Thank you, your payment has been sucessful and a confirmation email has been sent to <b>{priceModel?.customerEmail}</b></span></StyledText>
                </Column>
                {priceModel && <Column alignItems gap="5px">
                    <Text $heading5 $black>Total Payment Amount</Text>
                    <Text $heading3 $black>{formatCurrency(priceModel.currency ?? 'usd')}{price > 0 ? formatPrice(price) : formatPrice(0)}</Text>
                    {price <= 0 && <Text $heading5 $black>And you got {formatCurrency(priceModel.currency ?? 'usd')}{formatPrice(0 - price)} in credits.</Text>}
                    <Text $paragraph3 $black>Billed {priceModel.interval}ly</Text>
                </Column>}
            </TextWrapper>
        </CustomModal>
    )
}

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
`
const StyledText = styled(Text)`
    text-align: center;
`

export default PaymentConfirmedModal