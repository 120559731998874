import { FunctionComponent, useCallback, useEffect, useState } from 'react'
import { IConnection } from '../../interfaces/models/connection'
import { useExternalHandlers } from '../../services/actions/navigation/useExternalHandlers'
import { useAppStore } from '../../stores/application/useAppStore'
import { AppRoutes, SidebarTypes, TMenuItemState } from '../../interfaces/store/appStore'
import DropdownMenu from '../../components/dropdown/DropdownMenu'
import DropdownItem from '../../components/dropdown/DropdownItem'
import SvgFragment from '../SvgFragment'
import { useAppHandlers } from '../../services/actions/app/useAppHandlers'
import { useConnectionsHandlers } from '../../services/actions/connections/useConnectionsHandlers'
import Row from '../../components/Row'
import { useAuthStore } from '../../stores/auth/useAuthStore'
import Column from '../../components/Column'
import ActionText from '../../components/ActionText'
import { useAuthHandlers } from '../../services/actions/auth/useAuthHandlers'
import styled from 'styled-components'
import { styles } from '../../styles/themes/style'
import { isLNFirst } from './../../utils';

interface IConnectionActionDropdownProps { 
    connection: IConnection,
    backgroundColor?: string
}

const ConnectionActionDropdown: FunctionComponent<IConnectionActionDropdownProps> = ({connection, backgroundColor}) => {
    const { visitLIProfile } = useExternalHandlers() //get these from the above
    const { openSidebar, openExtension, setScheduleDisconnectModalHandler } = useAppHandlers()
    const { store: { user, isLoggedToLinkedin, workspace } } = useAuthStore()
    const { store: { extensionInstalled, extensionVersion } } = useAppStore()
    const { saveOnboardingData } = useAuthHandlers()
    const { visitChromeStore, openLinkedinWebsite, updateLeadDeltaExtension } = useExternalHandlers()
    const { updateConnectionHandler, followProfileHandler, unfollowProfileHandler, toggleHideConnectionHandler, scheduleConnectionsRemovalHandler } = useConnectionsHandlers()
    //some of them are for the sidebar and some are for the table, pay attention

    const isExtensionVersionCorrect = extensionVersion === process.env.REACT_APP_VERSION

    const [enableLinkedinAction, setEnableLinkedinAction] = useState<boolean>(false)

    useEffect(() => {
        setEnableLinkedinAction(isLNFirst(connection, user?._id))
    }, [connection])

    const updateProfile = useCallback(async () => {
        const result = await updateConnectionHandler(connection)
        if(result) connection = result
    }, [connection])

    const followProfile = useCallback(async () => {
        const result = await followProfileHandler(connection)
        if(result) connection = result //This should be state
    }, [connection])

    const unfollowProfile = useCallback(async () => {
        const result = await unfollowProfileHandler(connection)
        if(result) connection = result //This should be state
    }, [connection])

    const toggleHideUnhide = useCallback(async (value: boolean) => {
        const result = await toggleHideConnectionHandler(connection, value)
        if(result) connection = result //This should be state
    }, [connection])
    
    const installExtensionHandler = useCallback(async () => {
        await Promise.all([
            saveOnboardingData({
                startedExtensionInstallation: true,
            }),
            (async () => visitChromeStore())(),
        ]);
    },[]); 

    const connectionActionsInitial = [
        {
            icon: 'profile',
            title: 'Visit profile',
            slug: 'visitProfile',
            enabled: !!connection.publicIdentifier,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.publicIdentifier && visitLIProfile(connection.publicIdentifier)
        }, 
        {
            icon: 'label',
            title: 'Apply/Remove tags',
            slug: 'applyRemove',
            enabled: true,
            visible: true,
            onClickHandler: (connection: IConnection) => openSidebar(SidebarTypes.APPLY_TAG, { connection })
        },
        {
            icon: 'pen',
            title: 'Add task',
            slug: 'addTask',
            enabled: true,
            visible: true,
            onClickHandler: (connection: IConnection) => openSidebar(SidebarTypes.TASK_EDIT_CREATE, { formType: 'linked connection', connection })
        },
        {
            icon: 'message',
            title: 'Message',
            slug: 'messageProfile',
            enabled: !workspace?.isStarter && isLoggedToLinkedin && extensionInstalled && isExtensionVersionCorrect && connection.publicIdentifier && enableLinkedinAction,
            showInstallExt: !extensionInstalled || !isLoggedToLinkedin || !isExtensionVersionCorrect,
            visible: true,
            onClickHandler: (connection: IConnection) => openExtension({ query: `redirectTo=inbox&messagedConnection=${connection._id}` })
        },
        {
            icon: 'refresh',
            title: 'Update Profile',
            slug: 'updateProfile',
            enabled: isLoggedToLinkedin && extensionInstalled && isExtensionVersionCorrect && connection.profileId,
            showInstallExt: !extensionInstalled || !isLoggedToLinkedin || !isExtensionVersionCorrect,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.profileId && updateProfile()
        },
        {
            icon: 'profileAdd',
            title: 'Follow',
            slug: 'follow',
            enabled: isLoggedToLinkedin && extensionInstalled && isExtensionVersionCorrect && connection.publicIdentifier && enableLinkedinAction,
            showInstallExt: !extensionInstalled || !isLoggedToLinkedin || !isExtensionVersionCorrect,
            visible: true,
            onClickHandler: (connection: IConnection) => connection.profileId && followProfile()
        },
        {
            icon: 'profileRemove',
            title: 'Unfollow',
            slug: 'unfollow',
            enabled: isLoggedToLinkedin && extensionInstalled && isExtensionVersionCorrect && connection.publicIdentifier && enableLinkedinAction,
            showInstallExt: !extensionInstalled || !isLoggedToLinkedin || !isExtensionVersionCorrect,
            visible: true,
            destructive: true,
            onClickHandler: (connection: IConnection) => connection.profileId && unfollowProfile()
        },
        {
            icon: 'hide',
            title: 'Hide',
            slug: 'hide',
            enabled: true,
            visible: !connection.isHidden,
            destructive: true,
            onClickHandler: (connection: IConnection) => connection && toggleHideUnhide(true)
        },
        {
            icon: 'show',
            title: 'Unhide',
            slug: 'unhide',
            enabled: true,
            visible: connection.isHidden ?? false,
            onClickHandler: (connection: IConnection) => connection && toggleHideUnhide(false)
        },
        {
            icon: 'profileRemove',
            title: 'Remove',
            slug: 'remove',
            enabled: isLoggedToLinkedin && extensionInstalled && isExtensionVersionCorrect && connection.publicIdentifier && enableLinkedinAction,
            showInstallExt: !extensionInstalled || !isLoggedToLinkedin || !isExtensionVersionCorrect,
            visible: true,
            destructive: true,
            //onClickHandler: (connection: IConnection) => setDisconnectConnectionsModalHandler(disconnectRemove, undefined, undefined, undefined, enableLinkedinAction)
            onClickHandler: (connection: IConnection) => setScheduleDisconnectModalHandler(
                () => scheduleDisconnectHandler(connection),
                undefined,
                1
            )
        },
    ]

    useEffect(() => {
        setConnectionActions(connectionActionsInitial)
    }, [connection, enableLinkedinAction, extensionInstalled, isLoggedToLinkedin])

    const scheduleDisconnectHandler = useCallback(async (connection: IConnection) => {
        if(connection){
          scheduleConnectionsRemovalHandler([connection], false);
        }
    }, []);

    const [ connectionActions, setConnectionActions ] = useState<TMenuItemState[]>(connectionActionsInitial);
    //todo: further adjustment needed, on click handling states depending on the imported state etc.

    return <DropdownMenu className='connections-actions-dropdown' SVGtype='threeDots' $hideToggle tooltipMessage={'Actions'} $backgroundColor={backgroundColor}>
        {connectionActions.map(connectionAction => 
            connectionAction.visible && 
            <>
                {connectionAction?.title === 'Unfollow' && <Divider />}
                <DropdownItem key={connectionAction?.title} $disabled={!connectionAction.enabled} $danger={connectionAction.destructive} onClickHandler={() =>  connectionAction.onClickHandler && connectionAction.onClickHandler(connection)}>
                    { !connectionAction.showInstallExt ? (
                        workspace.isStarter && connectionAction?.title === 'Message' ? (
                            <Row alignItems spaceBetween gap="10px">
                                <Column>
                                    <Row alignItems gap="10px">
                                        <SvgFragment type={connectionAction.icon}/>
                                        {connectionAction.title}
                                    </Row>
                                </Column>
                                <Column>
                                    <Row alignItems gap="4px">
                                        <StyledActionText icon='lock2' 
                                            onClickHandler={(e: any) => {
                                                e.stopPropagation()
                                                window.open(`${window.location.origin}${AppRoutes.BILLING}`, '_blank');
                                            }}
                                        >
                                            { 'Upgrade'}
                                        </StyledActionText>
                                    </Row>
                                </Column>
                            </Row>
                        ) : (
                            <Row alignItems gap="10px">
                                <SvgFragment type={connectionAction.icon} />
                                {connectionAction.title}
                            </Row>
                        )
                    ) : (
                        <Row alignItems spaceBetween gap="10px">
                            <Column>
                                <Row alignItems gap="10px">
                                    <SvgFragment type={connectionAction.icon}/>
                                    {connectionAction.title}
                                </Row>
                            </Column>
                            <Column>
                                <Row alignItems gap="4px">
                                    <StyledActionText icon='lock2' 
                                        onClickHandler={(e: any) => {
                                            e.stopPropagation()
                                            if (!extensionInstalled) installExtensionHandler()
                                            if (!isExtensionVersionCorrect) updateLeadDeltaExtension()
                                            if (!isLoggedToLinkedin) openLinkedinWebsite()
                                            if (workspace.isStarter) window.open(`${window.location.origin}${AppRoutes.BILLING}`, '_blank');
                                        }}
                                    >
                                            {
                                            !extensionInstalled ? 'Install Extension' 
                                                : !isExtensionVersionCorrect ? 'Update extension' 
                                                : !isLoggedToLinkedin ? 'Login to Linkedin' 
                                                : 
                                                workspace.isStarter && connectionAction?.title === 'Message' ? 'Upgrade'
                                                : ''
                                            }
                                    </StyledActionText>
                                </Row>
                            </Column>
                        </Row>
                    )
                }
                </DropdownItem>
            </>)
            }
    </DropdownMenu>
}

const StyledActionText = styled(ActionText)`
    span {    
        color: ${styles.colors.black300};
        font-size: 12px;
    }

    &:hover{
        path { fill: ${styles.colors.primary400} }
    }

    div {
        margin-right: 4px;
        svg {
            margin-bottom: 4px;
        }
    }
`
const Divider = styled.div`
  height: 2px;
  margin: 0px 8x 0px 8px;
  background-color: ${styles.colors.uiBackground};
`;

export default ConnectionActionDropdown
