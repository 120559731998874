import { FunctionComponent, useEffect, useState } from "react";
import { useAuthStore } from "../stores/auth/useAuthStore";
import SvgFragment from "./SvgFragment";
import DropdownMenu from "../components/dropdown/DropdownMenu";
import styled from "styled-components";
import Text from "../components/Text";
import ImageNoNotifications from '../assets/img/no-notifications.png';
import { useNotificationsRequest } from "../services/apis/notifications/useNotificationsRequest";
import ProfilePhoto from "../components/ProfilePhoto";
import Row from "../components/Row";
import moment from "moment";
import DropdownItem from "../components/dropdown/DropdownItem";
import { styles } from "../styles/themes/style";
import { useNotificationsHandlers } from "../services/actions/notifications/useNotificationsHandlers";
import { useAuthActions } from "../stores/auth/useAuthActions";
import Badge from "../components/Badge";
import DropdownEmptyState from "../components/DropdownEmptyState";
import { useConnectionsHandlers } from "../services/actions/connections/useConnectionsHandlers";
import { useConnectionsStore } from "../stores/connections/useConnectionsStore";
import { AppRoutes } from "../interfaces/store/appStore";
import { useNavigate } from "react-router-dom";

interface INotiicationsProps { }

const Notifications: FunctionComponent<INotiicationsProps> = () => {
    const { store: { userWorkspaces, authData: { accessToken, refreshToken } } } = useAuthStore()

    const { setUserWorkspaces } = useAuthActions()
    const { markAsReadHandler } = useNotificationsHandlers() 
    const { getNotifications } = useNotificationsRequest() 

    const { store: { connectionsFilter } } = useConnectionsStore()
    const { setConnectionsFilterHandler } = useConnectionsHandlers()
    const navigate = useNavigate();

    const [notifications, setNotifications] = useState<any>({
        unread: null,
        notifications: null
    })

    const markAsRead = async () => {
        if(notifications.unread) {
            let selectedWorkspace = userWorkspaces.find((item: any) => item.selected)

            if(selectedWorkspace) selectedWorkspace.notifications.unread = 0

            const updatedUserWorkspaces = userWorkspaces.map((workspace: any) => {
                if(workspace.isSelected) {
                    return workspace.notifications.unread = 0
                } else {
                    return workspace
                }
            }) 

            setUserWorkspaces(updatedUserWorkspaces)

            setNotifications({ ...notifications, unread: null })

            await markAsReadHandler({all: true})
        }
    } 

    const notificationClickHadler = async (notification: any) => {
        const taggedUserId = notification?.meta?.userTagged;
        const taggedUser = notification?.receivers?.find((user: any) => user?._id === taggedUserId);
        if(taggedUserId && taggedUser){
            const newParams = { ...connectionsFilter, searchValues: [{
                searchValue: `${taggedUser?.firstName} ${taggedUser?.lastName}`,
                isValueSearchOr: "no",
                searchByName: "no",
                searchByHeadline: "no",
                searchByJobTitle: "no",
                searchByCompany: "no",
                searchByLocation: "no",
                searchByNote: "yes",
                isOutterValueSearchOr: "no"
            }] };
            setConnectionsFilterHandler(newParams);
            navigate(AppRoutes.CONNECTIONS);
        }
    }

    useEffect(() => {
        const fetchNotifications = async () => {
            let selectedWorkspace = userWorkspaces?.find((item: any) => item?.selected);
    
            if (selectedWorkspace && (accessToken && refreshToken)) {
                let { response } = await getNotifications();
                setNotifications({
                    unread: selectedWorkspace.notifications?.unread || 0,
                    notifications: response,
                });
            }
        };
    
        fetchNotifications();
    }, [userWorkspaces, accessToken, refreshToken])

    return (
        <DropdownMenu autoClose='outside' $hideToggle title={ <Badge top="-5px" left="20px" number={notifications.unread} onClick={markAsRead}><SvgFragmentWrapper><SvgFragment type='notificationBell' /></SvgFragmentWrapper></Badge>}>
            <div style={{padding: '10px 0px 20px 20px'}}><Text $heading4>Notifications</Text></div>
            {notifications?.notifications?.length ? (<>
                {notifications.notifications.map((notification: any, index: number) => {
                    const sender = notification.senders?.[0].firstName + ' ' + notification.senders?.[0].lastName
                    
                    return (
                        <DropdownItem 
                            key={index}
                            onClickHandler={() => notificationClickHadler(notification)}>
                            {(notification.type === 'schedule-connections-removal' 
                                || notification.type === 'cancel-connections-removal'
                                || notification.type === 'finish-connections-removal') ? (
                                <>
                                    <Row alignItems gap="10px">
                                        <SvgFragment type={
                                            notification.type === 'schedule-connections-removal' ? 'clockGrey' :
                                            notification.type === 'cancel-connections-removal' ? 'closeCircle' :
                                            'check'
                                        }/>
                                        <Row gap="5px" alignItems>
                                            <Text>{notification?.meta?.description1}</Text>
                                            <StyledText>{notification?.meta?.description2}</StyledText>
                                        </Row>
                                    </Row>
                                    <Row alignItems gap="26px">
                                        <Text></Text>
                                        <Text $lighter>{moment(notification.createdAt).fromNow()}</Text>
                                    </Row>
                                </>
                            ) : (
                                <>
                                    <Row alignItems gap="10px">
                                        <ProfilePhoto capitalLetters={`${notification.senders?.[0]?.firstName.charAt(0).toUpperCase()}${notification.senders?.[0]?.lastName.charAt(0).toUpperCase()}`} />
                                        <Text>{notification.type === 'note-create-tag' ? `${sender} created a new note and tagged you` : `${sender} updated the note where you are tagged`}</Text>
                                    </Row>
                                    <Text $lighter>Notes ∙ {moment(notification.createdAt).fromNow()}</Text>
                                </>
                            )}
                        </DropdownItem>
                    )
                })}
            </>) : (<>
                <DropdownEmptyState text={"No new notifications yet"} image={ImageNoNotifications} />
            </>)}
       </DropdownMenu>
    )
}

const SvgFragmentWrapper = styled.div`
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${styles.colors.primary100};
    border-radius: 50%;

    & svg {
        width: 16px;
        fill: transparent !important;
    }

    &:hover svg {
        opacity: 0.5;
    }
`
const StyledText = styled(Text)`
  display: inline-block;
  text-align: left;
  font-weight: normal;
`;

export default Notifications