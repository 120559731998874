import { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import Tag from '../../../components/Tag'
import Text from '../../../components/Text'
import { styles } from '../../../styles/themes/style'
import { IPrice } from '../../../interfaces/models/billingPlan'
import { formatCurrency, formatNumber, formatPrice } from '../../../utils'
import Row from '../../../components/Row'

interface IPlanValueProps {
    isSelected?: boolean
}

interface ICycleToggleProps extends IPlanValueProps {
    selectedInterval?: string,
    prices: IPrice[],
    onClickHandler?: (e: any) => void
}

export const calculateSaveAmount = (monthlyPrice?: IPrice, annualPrice?: IPrice): number => {
    if(monthlyPrice && annualPrice) {
        const annualMonthly = monthlyPrice?.price * 12
        return (annualMonthly - annualPrice?.price) / annualMonthly * 10000
    }
    return 0
}

const CycleToggle: FunctionComponent<ICycleToggleProps> = ({ selectedInterval, prices, onClickHandler }) => {

    const plansInitialValues = [
        {
            plan: 'Yearly',
            key: 'year',
            currency: 'usd',
            price: 0,
            save: '30% OFF'
        },
        {
            plan: 'Monthly',
            key: 'month',
            currency: 'usd',
            price: 0,
            save: ''
        }
    ]
    const [plansValues, setPlansValues] = useState<{
        plan: string,
        key: string,
        currency: string,
        price: number,
        save?: string
    }[]> (plansInitialValues)

    useEffect(() => {
        let monthlyPrice: IPrice | undefined
        let annualPrice: IPrice | undefined
        let save: number
        let newPlans = prices.map(price => {
            if(price.interval === 'month') monthlyPrice = price 
            if(price.interval === 'year') annualPrice = price 
            return {
                plan: price.interval,
                key: price.interval,
                price: price.price,
                currency: price.currency
            }
        })
        save = calculateSaveAmount(monthlyPrice, annualPrice)
        newPlans = newPlans.map(plan => plan.key === 'year' && save > 0 ? {...plan, save: `2 months off`} : {...plan})
        setPlansValues(newPlans)
    }, [prices])

    return (
        <Row gap='16px'>
            {plansValues.map((value, index) => (
                <PlanValue key={index} isSelected={selectedInterval === value.key} onClick={() => onClickHandler && onClickHandler({ plan: value.plan, key: value.key })}>
                    <Text $heading5 $capitalize>{`Bill ${value.plan}ly`}</Text>
                    <Row gap="8px" alignItems>
                        <Row gap="2px" alignItems>
                            <Text $heading5>{`${formatCurrency(value?.currency ?? 'usd')}${formatPrice(value?.price ?? 0)}`}</Text>
                            <Text $paragraph3 $capitalizeAll>{value.key}</Text>
                        </Row>
                        {value?.save && <Tag $color={styles.colors.green600} title={value.save} />}
                    </Row>
                </PlanValue>
            ))}
        </Row>
    )
}
const PlanValue = styled.div<IPlanValueProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ isSelected }) => isSelected ? styles.colors.primary600 : styles.colors.black200};
    border-radius: 4px;
    gap: 8px;
    padding: 16px;
    cursor: pointer;
    min-width: 200px;
    &:hover {
        opacity: 0.75;
    } 
`
export default CycleToggle