import React, { FormEvent, FunctionComponent, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Row from '../../../components/Row'
import StatBox from '../../../fragments/StatBox'
import { useAuthStore } from '../../../stores/auth/useAuthStore'
import { useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../../interfaces/store/appStore'
import { useAppHandlers } from '../../../services/actions/app/useAppHandlers'
import { useAnalyticsHandlers } from '../../../services/actions/analytics/useAnalyticsHandlers'
import { IChartAnalytics } from '../../../interfaces/response/analytics'
import DashboardChartBox from '../components/dashboard/DashboardChartBox'
import StatisticsAreaBox from '../components/dashboard/StatisticsAreaBox'
import { useAppStore } from '../../../stores/application/useAppStore'
import { useAppActions } from '../../../stores/application/useAppActions'

interface IDashboardProps { }

interface IDashboardAnalytics {
    "connections-total": number
    'connections-untagged': number
    'connections-messaged': number
    'connections-with-notes': number
    'tags-total': number
    'connections-updated-connected-to-linkedin': number
}

const DashboardSection: FunctionComponent<IDashboardProps> = () => {
    const { store: { user } } = useAuthStore()
    const { store: { requestLoading } } = useAppStore()
    const navigate = useNavigate()
    const { openExtension } = useAppHandlers()
    const [ isFetching, setIsFetching ] = useState<boolean>(true)
    const [ analytics, setAnalytics ] = useState<IDashboardAnalytics>({
        'connections-total': 0,
        "connections-untagged": 0,
        'connections-messaged':  0,
        'connections-with-notes': 0,
        'tags-total': 0,
        'connections-updated-connected-to-linkedin': 0
    })
    const [ chartsAnalytics, setChartAnalytics ] = useState<IChartAnalytics>({
        countries: [],
        jobs: [],
        industries: []
    })
    const { getDashboardOverviewAnalytics, getDashboardChartAnalytics } = useAnalyticsHandlers()
    
    useEffect(() => {
        if('analyticsProfileStatistics' in requestLoading) { setIsFetching(requestLoading?.['analyticsProfileStatistics']) }
    }, [requestLoading])

    useEffect(() => {
        setIsFetching(true)
        getDashboardOverviewAnalyticsHandler()
        getDashboardChartAnalyticsHandler()
    }, [])

    const getDashboardOverviewAnalyticsHandler = useCallback( async () => {
        const statistics = await getDashboardOverviewAnalytics();
        let statisticsObject = statistics.reduce((accumulator, value) => ({...accumulator, ...{[value.version]: value.value}}), {});
        setAnalytics({...analytics, ...statisticsObject})
    }, [])
    const getDashboardChartAnalyticsHandler = useCallback( async () => {
        const statistics = await getDashboardChartAnalytics();
        setChartAnalytics(processChartData(statistics?.value as IChartAnalytics))
    }, [])

    const selectedUsersMutual = [
        { 
            _id: user._id,
            firstName: user?.firstName,
            lastName: user?.lastName
        }
    ]

    const processChartData = (data: IChartAnalytics | undefined | null): IChartAnalytics => {
        if (!data) return {} as IChartAnalytics;
      
        return Object.fromEntries(
          Object.entries(data).map(([key, value]) => {
            if (Array.isArray(value)) {
              const processedArray = value
                .map(item => ({
                  ...item,
                  _id: item._id === '' ? 'Unknown' : item._id
                }))
                .sort((x, y) => {
                    return y.count - x.count;
                  });                  
              return [key, processedArray];
            }
            return [key, value];
          })
        ) as IChartAnalytics;
      };
    //todo: some navigation needs to include filtering
    return <Container>
        <GeneralSection gap="20px">
            <StatBox emptyBox />
            <StatBox
                svgType={'followers'}
                statistic={user?.linkedinData?.linkedinNetworkInfo?.followerCount || 0}
                title={'Followers'}
            />
            <StatBox
                svgType={'followings'}
                statistic={user?.linkedinData?.linkedinNetworkInfo?.followingCount || 0}
                title={'Followings'}
            />
        </GeneralSection>
        <GeneralSection gap="20px">
            <StatBox
                svgType={'connections'}
                statistic={analytics["connections-total"]}
                title={'Connections'}
                tooltipMessage={'Connections'}
                onClickHandler={ () => navigate(AppRoutes.CONNECTIONS, { state: { selectedUsersMutual } })}
            />
            <StatBox
                svgType={'tags'}
                statistic={analytics["tags-total"]}
                title={'Number of tags'}
                tooltipMessage={'Number of tags'}
                onClickHandler={() => navigate(AppRoutes.TAGS, { state: { selectedUsersMutual } })}
            />
            <StatBox
                    svgType={'tagged'}
                    statistic={analytics["connections-untagged"] || 0}
                    title={'Untagged connections'}
                    tooltipMessage={'Untagged connections'}
                    onClickHandler={() => navigate(AppRoutes.CONNECTIONS, { state: { noTags: true, selectedUsersMutual } })}
            />
            <StatBox
                svgType={'messaged'}
                statistic={analytics["connections-updated-connected-to-linkedin"] - analytics["connections-messaged"] || 0}
                title={'No conversation'}
                tooltipMessage={'Number of connections with no conversation recorded'}
                onClickHandler={() => navigate(AppRoutes.CONNECTIONS, { state: {
                        isAutotagsFilterOr: 'yes',
                        autotagsFilter: [
                            {
                                "_id": "New Connection",
                                "title": "New Connection",
                                "color": "#5964ff",
                                "isForbidden": true
                            },
                            {
                                "_id": "LinkedIn 1st",
                                "title": "LinkedIn 1st",
                                "color": "#5964ff",
                                "isForbidden": true
                            }
                        ],
                        selectedUsersMutual,
                        conversationStatus: "noConversationRecorded"
                    } 
                })}
            />
            <StatBox
                svgType={'notes'}
                statistic={analytics["connections-with-notes"] || 0}
                title={'Connections with notes'}
                tooltipMessage={'Number of connections with notes'}
                onClickHandler={() => navigate(AppRoutes.NOTES, { state: { selectedUsersMutual } })}
            />
        </GeneralSection>
        <GeneralSection gap="24px">
            <DashboardChartBox 
                type={'countries'} 
                title={'Most Popular Countries'} 
                data={!isFetching ? chartsAnalytics?.countries?.sort((x, y) => {
                    if (x._id === 'Unknown' && y._id !== 'Unknown') return 1;
                    if (y._id === 'Unknown' && x._id !== 'Unknown') return -1;
                    return 0;
                  })  : []
                } 
                fetchingData={isFetching} 
                total={(chartsAnalytics?.countries || []).reduce((result: number, item: any) => result += item.count, 0)}
                dataLineClickHandler={(e: any)=>navigate(AppRoutes.CONNECTIONS, { state: { locationSearch: e, selectedUsersMutual} })}
            />
            <StatisticsAreaBox
                type={'jobs'} 
                title={'Most Popular Job Titles'} 
                data={!isFetching ? chartsAnalytics?.jobs?.sort((x, y) => {
                    if (x._id === 'Unknown' && y._id !== 'Unknown') return 1;
                    if (y._id === 'Unknown' && x._id !== 'Unknown') return -1;
                    return 0;
                  })  : []
                } 
                fetchingData={isFetching} 
                total={(chartsAnalytics?.jobs || []).reduce((result, item) => result += item.count, 0)} 
                dataLineClickHandler={(e: any)=>navigate(AppRoutes.CONNECTIONS, { state: { jobTitleSearch: e, selectedUsersMutual } })}
            />
            <StatisticsAreaBox 
                type={'industries'} 
                title={'Most Popular Industries'} 
                data={!isFetching ? chartsAnalytics?.industries?.sort((x, y) => {
                    if (x._id === 'Unknown' && y._id !== 'Unknown') return 1;
                    if (y._id === 'Unknown' && x._id !== 'Unknown') return -1;
                    return 0;
                  }) : []
                } 
                fetchingData={isFetching} 
                total={(chartsAnalytics?.industries || []).reduce((result, item) => result += item.count, 0)} 
                dataLineClickHandler={(e: any)=>navigate(AppRoutes.CONNECTIONS, { state: { industry: e, selectedUsersMutual } })}
            />
        </GeneralSection>
    </Container>
}

const Container = styled.div`
    width: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100vh;
    overflow-y: auto;
`

const GeneralSection = styled(Row)`
    flex-wrap: wrap;
`

export default DashboardSection
