import { useAxios } from "../useAxios"
import { useAuthStore } from "../../../stores/auth/useAuthStore"


export const useTemplateRequest = () => {
    const { sendRequest } = useAxios()

    const route = '/templates'

    const getTemplates = (params: any) => {
        return sendRequest({
            slug: 'getTemplates',
            url: `${route}`,
            method: 'GET',
            params
        })
    };

    const paginateTemplates = (params: any) => {
        return sendRequest({
            slug: 'paginateTemplates',
            url: `${route}/paginate`,
            method: 'GET',
            params
        })
    };

    const createTemplate = (payload: any) => {
        return sendRequest({
            all: true,
            displayToast: false,
            slug: 'createTemplate',
            url: `${route}`,
            method: 'POST',
            payload
        })
    };

    const updateTemplate = (templateId: string, payload: any) => {
        return sendRequest({
            displayToast: false,
            slug: 'updateTemplate',
            url: `${route}/${templateId}`,
            method: 'PUT',
            payload
        })
    };

    const bulkUpdateTemplates = (payload: any) => {
        return sendRequest({
            displayToast: false,
            slug: 'bulkUpdateTemplates',
            url: `${route}/bulk/updateTemplates`,
            method: 'PUT',
            payload
        })
    };

    const deleteTemplate = (templateId: string) => {
        return sendRequest({
            displayToast: false,
            all: true,
            slug: 'deleteTemplate',
            url: `${route}/${templateId}`,
            method: 'DELETE'
        })
    };

    const bulkDeleteTemplates = (payload: any) => {
        return sendRequest({
            displayToast: false,
            all: true,
            slug: 'bulkDeleteTemplates',
            url: `${route}/bulk/deleteTemplates`,
            method: 'DELETE',
            payload
        })
    };

    return {
        getTemplates,
        paginateTemplates,
        createTemplate,
        updateTemplate,
        bulkUpdateTemplates,
        deleteTemplate,
        bulkDeleteTemplates
    };
}