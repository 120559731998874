import { FunctionComponent } from "react";
import styled from "styled-components";
import DropdownMenu from "../../components/dropdown/DropdownMenu";
import SvgFragment from "../SvgFragment";
import DropdownItem from "../../components/dropdown/DropdownItem";
import Row from "../../components/Row";
import { styles } from "../../styles/themes/style";

interface ITagsBulkActionsDropdownProps {
  className?: string;
  selectedTags?: any;
  deleteTagsHandler?: (e: any) => void;
  updateAccessHandler?: (isPrivate: boolean) => void;
}

const TagsBulkActionsDropdown: FunctionComponent<
  ITagsBulkActionsDropdownProps
> = ({ className, selectedTags, deleteTagsHandler, updateAccessHandler }) => {
  const bulkTagActions = [
    {
      title: `Update Access`,
      slug: "updateAccess",
      enabled: true,
      visible: true,
      destructive: true,
      divider: true,
      onClickHandler: (isPrivate?: boolean) =>
        updateAccessHandler &&
        updateAccessHandler(isPrivate !== undefined ? isPrivate : true),
    },
    {
      icon: "trashCan",
      title: `Delete Tag`,
      slug: "deleteTag",
      enabled: true,
      visible: true,
      destructive: true,
      onClickHandler: () =>
        deleteTagsHandler &&
        deleteTagsHandler(selectedTags?.map((tag: any) => tag?._id)),
    },
  ];

  return (
    <DropdownMenu
      tooltipMessage={"More actions"}
      disabled={selectedTags?.length === 0}
      className={className}
      $visibleOverflow={true}
      title={
        <SvgFragmentWrapper>
          <SvgFragment type="threeDots" />
        </SvgFragmentWrapper>
      }
      $hideToggle
    >
      {bulkTagActions.map((connectionAction, index) =>
        index === 0 ? (
          <>
            <StyledDropdownItem>
              <StyledDropdownMenu
                key={index}
                disabled={selectedTags.length === 0}
                title={"Update Access"}
              >
                <DropdownItem
                  onClickHandler={() => connectionAction.onClickHandler(true)}
                >
                  Private
                </DropdownItem>
                <DropdownItem
                  onClickHandler={() => connectionAction.onClickHandler(false)}
                >
                  Public
                </DropdownItem>
              </StyledDropdownMenu>
            </StyledDropdownItem>
            <Divider />
          </>
        ) : (
          <DropdownItem
            $disabled={!connectionAction.enabled}
            key={index}
            $danger={connectionAction.destructive}
            onClickHandler={(e) =>
              connectionAction.onClickHandler &&
              connectionAction.onClickHandler()
            }
          >
            <Row alignItems gap="5px">
              <SvgFragment type={connectionAction.icon} />
              {connectionAction.title}
            </Row>
          </DropdownItem>
        )
      )}
    </DropdownMenu>
  );
};

const StyledDropdownItem = styled(Row)`
  padding: 6px 20px;
  .actions-dropdown + div{
    min-width: 128px;
  }
`;
const SvgFragmentWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledDropdownMenu = styled(DropdownMenu)`
  height: 34px;
  background-color: ${styles.colors.white};

  & .dropdown-toggle {
    padding: 7px;
  }
`;
const Divider = styled.div`
  height: 2px;
  margin: 8px 16px 2px 16px;
  background-color: rgb(218, 224, 232);
`;

export default TagsBulkActionsDropdown;
