import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef, useState } from "react"
import moment from 'moment';
import styled from "styled-components"
import { styles } from "../../../../../styles/themes/style";

import TableCell from "../../../../../components/table/TableCell"
import TableData from "../../../../../components/table/TableData"
import TableHeader from "../../../../../components/table/TableHeader"
import TableRow from "../../../../../components/table/TableRow"
import Text from "../../../../../components/Text"
import Tag from "../../../../../components/Tag";
import Button from "../../../../../components/Button";
import { InvoceTableDataPreloader, InvoceTableRowPreloader } from "../../../../billing/preloaders/InvoiceTableTadaPreloader";
import { useAppStore } from "../../../../../stores/application/useAppStore";
import { useBillingHandlers } from "../../../../../services/actions/billing/useBillingHandlers";
import { IInvoice } from "../../../../../interfaces/models/invoice";
import Table from "../../../../../components/Table";
import { calculateColumnWidth, formatPrice } from "../../../../../utils";
import SvgFragment from "../../../../../fragments/SvgFragment";
import EmptyPage from "../../../../../components/EmptyPage";
import useMediaQuery from "../../../../../components/useMediaQuery";
import ActionText from "../../../../../components/ActionText";


interface IInvoicesTableProps { }

const InvoicesTable: FunctionComponent<IInvoicesTableProps> = () => {
    const [ invoices, setInvoices ] = useState<IInvoice[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [lastInvoiceId, setLastInvoiceId] = useState<string>('')
    const { store: { requestLoading } } = useAppStore()
    const { getInvoicesHandler } = useBillingHandlers()
    const tableRef = useRef<HTMLDivElement>(null)

    const isSmall = useMediaQuery("(max-width: 768px)");

    const noResultTitle = "No Billing history to Show";
    const noResultDescription = "";

    const [columns, setColumns] = useState([
        { header: 'Invoice', key: ['invoiceColumn'], show: true, showSmall: true },
        { header: 'Status', key: ['statusColumn'], show: true, showSmall: true },
        { header: 'Amount', key: ['amountColumn'], show: true, showSmall: true },
        { header: 'Date', key: ['dateColumn'], show: true },
        { header: 'Download', key: ['downloadColumn'], show: true, showSmall: true }
    ])
    useEffect(() => {
        const offsetWidth = tableRef.current?.offsetWidth

        const adjustedColumns = calculateColumnWidth(offsetWidth, columns, isSmall)   
        setColumns(adjustedColumns)

    }, [isSmall])

    const handleScroll = async (e: React.UIEvent<HTMLElement>) => {
        if ((e.currentTarget.scrollTop + e.currentTarget.offsetHeight) === e.currentTarget.scrollHeight) {
            if (lastInvoiceId !== invoices?.[invoices.length - 1]?.id) {
                setLastInvoiceId(invoices[invoices.length - 1].id)
            }

        }
    }
    useEffect(() => {
        if('invoices' in requestLoading) setIsLoading(requestLoading?.['invoices'])
    }, [requestLoading])

    useEffect(() => {
        setInvoicesHandler(lastInvoiceId)
    }, [lastInvoiceId])

    const setInvoicesHandler = useCallback( async (lastInvoiceId?: string) => {
        const result = await getInvoicesHandler(lastInvoiceId)      
        setInvoices([...invoices, ...result])
    }, [invoices])

    const isMobile = useMediaQuery('(max-width: 768px)');

    return (<Table
                pageLoading={(isLoading && !invoices?.length) ? 5 : undefined}
                noDataPage={<EmptyPage svgType='billingEmpty' title={noResultTitle} label={noResultDescription} />}
                appendLoading={invoices?.length > 0 && isLoading}
                ref={tableRef}
                $height='100%'
                onScrollHandler={(e) => !isLoading && handleScroll(e)} 
                columns={[
                    ...columns
                ]}
                data={invoices.map((invoice: any) => {
                    return ({
                        invoiceColumn: <Text $bold $black $ellipsis="0">{invoice.productName && `${invoice.productName} - `}{moment.unix(invoice.createdAt).format('MMM YYYY')}</Text>,
                        statusColumn: (invoice.status === 'paid' && <StyledTag><Tag title={'Paid'} $borderColor={styles.colors.green600} $color={styles.colors.green200} SVGtype={'checkmark'} /></StyledTag>),
                        amountColumn: <Text $lighter>{invoice.currency === 'usd' ? '$' : '€'}{formatPrice(invoice.amountDue)}</Text>,
                        dateColumn: moment.unix(invoice.createdAt).format('MMM D, YYYY'),
                        downloadColumn: (
                            <StyledButton>
                              {!isMobile && <Button className="download-button" $type="blue" onClickHandler={() => window.open(invoice.downloadUrl, '_blank')}>Download</Button>}
                              {isMobile && <ActionText className="action-text" onClickHandler={() => window.open(invoice.downloadUrl, '_blank')}>Download</ActionText>}
                            </StyledButton>
                          ),
                        

                    })
                })}
            />)
}
const StyledTag = styled.div`
    background-color: ${styles.colors.green200};
    width: 20px;

    & svg {
        width: 20px;
        height: 16px
    }
`
const StyledButton = styled(Button)`
    display: flex;
    align-items: center;
`
const StyledActionText = styled(ActionText)`
    display: none;
    @media (max-width: 786px) {
        display: block;
    }
`
export default InvoicesTable