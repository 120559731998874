import React, { FormEvent, FunctionComponent, useCallback, useState } from 'react'
import styled from 'styled-components'
import Column from '../../../../../components/Column'
import Row from '../../../../../components/Row'
import Text from '../../../../../components/Text'
import Button from '../../../../../components/Button'
import { useAuthHandlers } from '../../../../../services/actions/auth/useAuthHandlers'
import { useAuthStore } from '../../../../../stores/auth/useAuthStore'
import moment from 'moment'
import InputNumber from '../../../../../components/InputNumber'
import debounce from 'lodash.debounce'
import { useAppHandlers } from '../../../../../services/actions/app/useAppHandlers'
import { styles } from '../../../../../styles/themes/style'

interface IBackgroundUpdateProps { }

const BackgroundUpdate: FunctionComponent<IBackgroundUpdateProps> = () => {

    const { store: { user } } = useAuthStore()
    const { syncConnectionsHandler, saveUserPreferencesHandler } = useAuthHandlers()
    const { setSyncConnectionsModalHandler } = useAppHandlers()
    const setLastSyncAt = useCallback(async() => {
        if(!user?.isSynced) setSyncConnectionsModalHandler((user) => syncConnectionsHandler(user))
        else syncConnectionsHandler(user)
    }, [user])

    const setUpdateLimit = debounce(async (value: number) => {
        await saveUserPreferencesHandler({ dailyUpdateLimit: value })
    }, 500)

    const lastSyncAtLocalStorage = window.localStorage.getItem('lastSyncAt')
    const lastSyncAt = lastSyncAtLocalStorage && lastSyncAtLocalStorage !== 'undefined' ? lastSyncAtLocalStorage : 0

    return <>
            <StyledColumn gap="40px">
                <StyledRow spaceBetween gap='40px'>
                    <Column gap='16px'>
                        <Text $label $capitalize>{'Manual sync'}</Text>
                        <Text $lighter>
                        Manually sync your Connections. “Sync” synchronizes the latest state from LinkedIn to LeadDelta (i.e. NEW connections, removed connections, etc.).
                        </Text>
                        {lastSyncAt !== 0 && <Text $lighter>{`Last sync at ${moment(lastSyncAt).format('MMM D, YYYY')}`}</Text>}
                    </Column>
                    <Button $type="grey" $extraBigButton type="submit" $SVGtype='refresh' disabled={moment().isBefore(moment(lastSyncAt).add(15, 'minutes'))} onClickHandler={()=>setLastSyncAt()}>Sync connections</Button>
                </StyledRow>
                <StyledRow spaceBetween gap='40px'>
                    <Column gap='16px'>
                        <Text $label $capitalize>{'24-hour limit'}</Text>
                        <Text $lighter>
                        {'The number of connections to be updated (automatically or manually) within 24hrs every time you log into LeadDelta. This limit is set for your own safety and should be used to mimic your regular LinkedIn usage.'}
                        </Text>
                        <Text $lighter>{`Increasing the recommended limit can lead to triggering LinkedIn automation tools warning.`}</Text>
                    </Column>
                    <InputNumber prefilledValue={user?.preferences?.dailyUpdateLimit ?? 25} minValue={0} onChangeHandler={(value: number) => setUpdateLimit(value)}/>
                </StyledRow>
            </StyledColumn>
            <MobileContainer>
                <StyledColumnMobile>
                    <StyledColumnMobile>
                        <StyledRowMobile>
                            <Text $label $capitalize>{'Manual sync'}</Text>
                            <Button $type="light-blue" $customWidth type="submit" $SVGtype='refresh' disabled={moment().isBefore(moment(lastSyncAt).add(15, 'minutes'))} onClickHandler={()=>setLastSyncAt()}>Sync connections</Button>
                        </StyledRowMobile>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        <Text $lighter>
                            Manually sync your Connections. “Sync” synchronizes the latest state from LinkedIn to LeadDelta (i.e. NEW connections, removed connections, etc.).
                        </Text>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        {lastSyncAt !== 0 && <Text $lighter>{`Last sync at ${moment(lastSyncAt).format('MMM D, YYYY')}`}</Text>}
                    </StyledColumnMobile>
                </StyledColumnMobile>
                <Divider />
                <StyledColumnMobile>
                <StyledColumnMobile>
                        <StyledRowMobile>
                            <Text $label $capitalize>{'24-hour limit'}</Text>
                            <InputNumber prefilledValue={user?.preferences?.dailyUpdateLimit ?? 25} minValue={0} onChangeHandler={(value: number) => setUpdateLimit(value)}/>
                        </StyledRowMobile>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        <Text $lighter>
                            {'The number of connections to be updated (automatically or manually) within 24hrs every time you log into LeadDelta. This limit is set for your own safety and should be used to mimic your regular LinkedIn usage.'}
                        </Text>
                    </StyledColumnMobile>
                    <StyledColumnMobile>
                        <Text $lighter $paragraph3>{`Increasing the recommended limit can lead to triggering LinkedIn automation tools warning.`}</Text>
                    </StyledColumnMobile>
                </StyledColumnMobile>
            </MobileContainer>
        </>
}

const StyledRow = styled(Row)`
    @media (max-width: 786px) {
        display: none;
    }
`
const StyledColumn = styled(Column)`
    @media (max-width: 786px) {
        display: none;
    }
`
const MobileContainer = styled.div`
    display: none;
    width: 100%;

    @media (max-width: 786px) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`
const StyledRowMobile = styled(Row)`
    display: none;
    @media (max-width: 786px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`
const StyledColumnMobile = styled(Column)<{ marginTop?: string }>`
    display: none;
    @media (max-width: 786px) {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        margin-top: ${({marginTop}) => marginTop ? marginTop : '0px' };
    }
`    
const Divider = styled.div`
  height: 2px;
  margin: 0px 8x 0px 8px;
  background-color: ${styles.colors.uiBackground};
`;
export default BackgroundUpdate
